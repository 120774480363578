import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { SelectButton } from 'primereact/selectbutton'
import { useCustomer } from '../hooks/CustomerContext'
import { spaOptions } from '../../../config/constants'
import { filterTypes } from '../prepareData'
// import useCustomerAppContext from '../hooks/useCustomerAppContext'

function Header() {
  const { filters, updateFilters, getListCustomers, setIsLoading } = useCustomer()
  const [spa, setSpa] = useState(spaOptions[0]?.value)

  const handleRefresh = () => {
    setIsLoading(true)
    getListCustomers(filters)
    // getCountInServiceCustomers(listCustomersStatuses.inServices)
  }

  // const onClickCheckIn = () => {
  //   // Mở popup cho staff tự check-in cho customers
  // }

  const handleChangeSpa = value => {
    if (![null, undefined].includes(value)) {
      setSpa(value)
      updateFilters(filterTypes.SPA, value)
    }
  }

  return (
    <div className='table-header'>
      <div className='d-flex'>
        <span className='header-title'>List of Customers</span>
        <SelectButton className="select-button-spa ml-3"
          value={spa}
          options={spaOptions}
          optionLabel="name"
          onChange={(e) => handleChangeSpa(e.value)} />
      </div>

      <Button label="Refresh" icon="pi pi-refresh"
        className='btn-refresh sm-btn'
        onClick={handleRefresh}
      />
      {/* <Button label="Check in" icon="pi pi-sign-in"
        className="btn-checkin sm-btn ml-2"
        onClick={onClickCheckIn}
      /> */}
      {/* <Button label="Excel" icon="pi pi-download" className="btn-export sm-btn-export"
          onClick={handleExportExcel}
          style={{ marginLeft: '0.5rem' }}
        /> */}
    </div>
  )
}

export default Header