import React, { useRef, useState } from 'react'
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tree } from 'primereact/tree';
import { Ripple } from 'primereact/ripple';
import { Toast } from 'primereact/toast';
import { ScrollTop } from 'primereact/scrolltop';
// import Loading from '../../../components/Loading'
import { useBooking } from '../hooks/BookingContext'
import { useApp } from '../../../hooks/AppContext'
import { bookingStatuses as listBookingStatuses } from '../../../utils/constants'
import { parseDateToString } from '../../../utils/DateTime'
import { getServicesMenu } from '../../../services/ServicesApi'
// import { getTechnicians } from '../../../services/TechniciansApi'
import { refractorTableData, refractorServicesList, getDateRangeInitialValues } from './prepareData'
import { DetailDialog, UpdateStatusDialog } from '../dialogs'
import { actionTypes, filterTypes } from '../hooks/filter-types'
import Highlighter from "react-highlight-words"
import moment from 'moment-timezone'
// import styled from 'styled-components'
import './StyledTable.css'

function StyledTable({ data, refreshData, totalElements, totalPage, setLoading }) {
  //=================================States Management=================================
  const { params, dispatchParams, page, limit, direction, sortBy, // setDirection,
    globalSearch, name, serviceIds, bookingStatus, setFilter, // start, end, technicianIds, 
    listServices, setListServices,      // listTechnicians, setListTechnicians, 
  } = useBooking()
  const { getCountPendingBooking } = useApp()
  // const [first, setFirst] = useState(0);
  // // const [rows, setRows] = useState(params?.limit);
  const bookingStatuses = Object.values(listBookingStatuses)

  // const [cusName, setCusName] = useState(null);
  const [range, setRange] = useState(getDateRangeInitialValues(params?.start, params?.end));
  // const [selectedTechnicians, setSelectedTechnicians] = useState(null);
  // const [selectedServices, setSelectedServices] = useState(null);
  // const [selectedBookingStatus, setSelectedBookingStatus] = useState('')
  const dt = useRef(null);

  const toast = useRef(null);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null)

  const appointmentData = refractorTableData(data)

  // Call API to get list only when click to open the select box
  // const onOpenTechniciansSelectBox = () => {
  //   // console.log(listTechnicians);
  //   if (listTechnicians?.length < 2) {
  //     getTechnicians().then(res => {
  //       setListTechnicians([...listTechnicians, ...res])
  //     })
  //   }
  // }

  const onOpenServicesSelectBox = () => {
    if (listServices?.length < 1) {
      getServicesMenu().then(res => {
        setListServices(refractorServicesList(res))
      })
    }
  }

  //=============Handle actions and dispatch to Reducer (filter by back-end)=============
  const timeoutRef = useRef(null)
  const handleSearch = (e) => {
    const searchKey = e?.target?.value
    setFilter(filterTypes.GLOBAL_SEARCH, searchKey)

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      dispatchParams({
        type: actionTypes.SEARCH,
        payload: searchKey
      })
      getCountPendingBooking(listBookingStatuses.pending)
    }, 350)
  }

  const handleSort = (sortValue) => {
    setFilter(filterTypes.COLUMN, sortValue?.sortField)
    setFilter(filterTypes.DIRECTION, sortValue?.sortOrder === -1 ? 'DESC' : 'ASC')
    dispatchParams({
      type: actionTypes.SORT,
      payload: {
        sortBy: sortValue?.sortField,
        direction: sortValue?.sortOrder === -1 ? 'DESC' : 'ASC'
      }
    })
    getCountPendingBooking(listBookingStatuses.pending)
  }

  // Ủa sao nó ko nhận đc cái này ta???
  // const handleSort = (e) => {
  //     console.log('sorting: ', e);
  //     // if (col.sortable) {
  //     //     setDirection(direction === 'DESC' ? 'ASC' : 'DESC')
  //     //     setSortBy(col.key)

  //     //     dispatchParams({
  //     //         type: ReducerActions.SORT_BY,
  //     //         payload: {
  //     //             sortBy: col.key,
  //     //             direction: direction,
  //     //         },
  //     //     })
  //     // }
  // }

  const onCusNameChange = (event) => {
    const cusName = event?.target?.value
    setFilter(filterTypes.CUS_NAME, cusName)
    console.log('cusName = ', cusName);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      dispatchParams({
        type: actionTypes.FILTER_CUS_NAME,
        payload: cusName
      })
      getCountPendingBooking(listBookingStatuses.pending)
    }, 350)

    // dt.current.filter(event.value, 'name', 'equals')     // Search dưới BE
    // dt.current.filter(event.value, 'email', 'equals')
    // dt.current.filter(event.value, 'phone', 'equals')
  }
  const onDateRangeFilterChange = (event) => {
    // gọi reducer truyền vào API
    // console.log('event.value = ', event.value);
    const selectedRange = event.value
    setRange(selectedRange)   // event.value = [fromDate, toDate] | fromDate, toDate default = null

    // console.log('selectedRange: ', selectedRange);

    if (selectedRange) {
      // console.log('date range = ', selectedRange);
      if (selectedRange[0]) {
        dispatchParams({
          type: actionTypes.FILTER_FROM_DATE,
          payload: `${parseDateToString(selectedRange[0], 'YYYY-MM-DD')} 00:00:00`
        })
        setFilter(filterTypes.FROM_DATE, selectedRange[0])
        getCountPendingBooking(listBookingStatuses.pending)
      }

      if (selectedRange[1]) {
        dispatchParams({
          type: actionTypes.FILTER_TO_DATE,
          payload: `${parseDateToString(selectedRange[1], 'YYYY-MM-DD')} 23:59:59`
        })
        setFilter(filterTypes.TO_DATE, selectedRange[1])
      } else {
        dispatchParams({
          type: actionTypes.FILTER_TO_DATE,
          payload: `${parseDateToString(selectedRange[0], 'YYYY-MM-DD')} 23:59:59`
        })
        setFilter(filterTypes.TO_DATE, selectedRange[0])
      }
    }
  }
  const onDateRangeFilterClear = (event) => {
    setRange(event.value)
    setFilter(filterTypes.FROM_DATE, null)
    setFilter(filterTypes.TO_DATE, null)

    dispatchParams({
      type: actionTypes.FILTER_FROM_DATE,
      payload: null
    })
    dispatchParams({
      type: actionTypes.FILTER_TO_DATE,
      payload: null
    })
    getCountPendingBooking(listBookingStatuses.pending)
  }

  //   const onTechnicianFilterChange = (event) => {
  //     const selectedIds = event.value
  //     // setSelectedTechnicians(selectedIds);

  //     dispatchParams({
  //       type: actionTypes.FILTER_TECHNICIANS,
  //       payload: selectedIds
  //     })
  //     setFilter(filterTypes.TECHNICIANS, selectedIds)
  //     // dt.current.filter(event.value, 'technician.name', 'in');     // Ko filter phía FE nữa. Cái này BE lo rồi
  //     getCountPendingBooking(listBookingStatuses.pending)
  //   }
  const onServiceFilterChange = (event) => {
    const selectedIds = event.value
    // setSelectedServices(selectedIds)

    dispatchParams({
      type: actionTypes.FILTER_SERVICES,
      payload: selectedIds
    })
    setFilter(filterTypes.SERVICES, selectedIds)
    // dt.current.filter(event.value, '', 'in');
    getCountPendingBooking(listBookingStatuses.pending)
  }

  const onStatusFilterChange = (event) => {
    const selectedStatus = event.value
    setFilter(filterTypes.BOOKING_STATUS, selectedStatus);
    dispatchParams({
      type: actionTypes.FILTER_BOOKING_STATUS,
      payload: selectedStatus
    })
    getCountPendingBooking(listBookingStatuses.pending)

    // dt.current.filter(event.value, 'status', 'equals');
  }

  //========================Render custom elements (components)========================
  const renderHeader = () => {
    return (
      <div className="table-header">
        <span className="header-title">List of Appointments</span>
        <Button label="Refresh" icon="pi pi-refresh" className="sm-btnRefresh"
          onClick={() => {
            refreshData(params)
            setSelectedRow(null)
            getCountPendingBooking(listBookingStatuses.pending)
            setLoading(true)
          }}
          style={{ marginRight: '0.5rem' }} />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={handleSearch} placeholder="Search anything..." />
        </span>
      </div>
    );
  }

  //--------------------------Element body template--------------------------
  const idBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">ID</span>
        <span>{rowData?.id}</span>
      </React.Fragment>
    );
  }

  const cusNameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Customer Info</span>
        <span className="cusName">
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[globalSearch, name]}
            autoEscape={true}
            textToHighlight={rowData?.name || ''}
            highlightStyle={{ backgroundColor: 'lightgreen' }}
          />
        </span> <br />
        <span className="cusPhone">
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[globalSearch]}
            autoEscape={true}
            textToHighlight={rowData?.phone || ''}
            highlightStyle={{ backgroundColor: 'yellow' }}
          />
        </span> <br />
        {rowData?.email && <div className="cusEmailWrapper">
          <span className="cusEmail">
            <Highlighter
              highlightClassName="YourHighlightClass"
              searchWords={[globalSearch]}
              autoEscape={true}
              textToHighlight={rowData?.email || ''}
              highlightStyle={{ backgroundColor: 'yellow' }}
            />
          </span>
        </div>}
      </React.Fragment>
    );
  }
  const createdDateBodyTemplate = (rowData) => {
    const createdAt = rowData?.createdDate;
    const dateStrFormat = createdAt ? parseDateToString(rowData?.createdDate, 'MM-DD-YYYY . . . HH:mm:ss') : ''
    return (
      <React.Fragment>
        <span className="p-column-title">Created at</span>
        <span>{dateStrFormat}</span>
      </React.Fragment>
    );
  }
  const dateBodyTemplate = (rowData) => {
    const dateStr = rowData?.time?.substring(0, rowData?.time?.indexOf(' '))
    const dateStrFormat = parseDateToString(dateStr, 'MMM DD, YYYY')
    // console.log(`.${rowData?.time?.substring(0, rowData?.time?.indexOf(' '))}.`)
    return (
      <React.Fragment>
        <span className="p-column-title">Date</span>
        <span>{dateStrFormat}</span>
      </React.Fragment>
    );
  }
  const timeBodyTemplate = (rowData) => {
    // console.log(`.${rowData?.time?.substring(rowData?.time?.indexOf(' ') + 1, rowData?.time?.lastIndexOf(':'))}.`)
    return (
      <React.Fragment>
        <span className="p-column-title">Time</span>
        <span>{rowData?.time?.substring(rowData?.time?.indexOf(' ') + 1, rowData?.time?.lastIndexOf(':'))}</span>
      </React.Fragment>
    );
  }
  //   const technicianBodyTemplate = (rowData) => {
  //     // const src = "assets/images/avatars/" + rowData?.technicianName + '.jpg';
  //     const blankAvatarSrc = 'assets/images/avatars/blank-ava.jpg'    // ko hiểu sao tôi ko tài nào dẫn link ảnh từ trong "src" đc nên phải bê ra folder "public"
  //     const technician = rowData?.technician
  //     return (
  //       <div className="technician">
  //         <span className="p-column-title">Technician</span>

  //         <img src={technician?.avatar ? technician.avatar : blankAvatarSrc} alt={technician?.name} className="staff-avatar"
  //           onError={(e) => e.target.src = blankAvatarSrc}
  //         />
  //         <span style={{ verticalAlign: 'middle', marginLeft: '.6em' }}>
  //           {technician?.name ? technician.name : 'Anyone'}
  //         </span>
  //       </div>
  //     );
  //   }
  const serviceBodyTemplate = (rowData) => {
    // setNodes(rowData?.options)
    return (
      <React.Fragment>
        <span className="p-column-title">Booked Services</span>
        {/* <span style={{ verticalAlign: 'middle', marginLeft: '.5em' }}>{rowData?.id}</span> */}
        <Tree value={rowData?.options} />

        {/* Chưa biết làm sao để highlight tên services search đc nữa */}
        {/* <Highlighter
                    highlightClassName="YourHighlightClass"
                    searchWords={[globalSearch]}
                    autoEscape={true}
                    textToHighlight={rowData?.options || ''}
                    highlightStyle={{ backgroundColor: 'yellow' }}
                /> */}
      </React.Fragment>
    );
  }
  const statusBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={classNames('appointment-badge', 'status-' + rowData?.status)}>
          {rowData?.status}
        </span>
      </React.Fragment>
    );
  }
  const actionBodyTemplate = (rowData) => {
    const salonNow = moment.tz(new Date(), 'America/Chicago').format('YYYY-MM-DD');
    const appointmentTime = rowData?.time?.split(' ')[0]
    // const appointmentTime = moment.tz(rowData?.time, 'Ameriaca/Chicago')
    // const appointmentTime = moment.tz(, 'Ameriaca/Chicago')

    // console.log('salonNow = ', salonNow);
    // console.log('appointmentTime = ', appointmentTime);
    // console.log('diabled = ', moment(salonNow).isAfter(appointmentTime));

    // Chú ý timezone
    // if (moment(salonNow).isBefore(new Date(rowData?.time))) console.log('disable');

    return <Button id="btnEdit" type="button" icon="pi pi-pencil" className="p-button-warning p-button-rounded"
      onClick={() => {
        setSelectedRow(rowData)
        setOpenUpdateDialog(true)
        if (openDetailDialog) setOpenDetailDialog(false)
      }}
      disabled={moment(salonNow).isAfter(appointmentTime) && rowData?.status !== listBookingStatuses.pending}    // So sánh nếu today > bookedDate thì disable
    />
  }

  //--------------------------Filters--------------------------
  // Customer name filter
  const renderNameFilter = () => {
    return <InputText type="search" value={name} onChange={(e) => onCusNameChange(e)}
      placeholder="Search by name" style={{ width: '100%' }} />
  }

  // Appointment time (date range) filter
  const monthNavigatorTemplate = (e) => {
    return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
  }
  const yearNavigatorTemplate = (e) => {
    return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2" style={{ lineHeight: 1 }} />;
  }
  const renderDateRangeFilter = () => {
    return <Calendar value={range} onChange={onDateRangeFilterChange}
      placeholder="From Date - To Date" dateFormat="mm/dd/yy"
      selectionMode="range" readOnlyInput showButtonBar
      monthNavigator yearNavigator yearRange="2010:2030"
      monthNavigatorTemplate={monthNavigatorTemplate}
      yearNavigatorTemplate={yearNavigatorTemplate}
      className="p-column-filter"
      onClearButtonClick={onDateRangeFilterClear}
    />
  }


  //=====================================Technicians filter (tạm khóa)=====================================
  // const technicianItemTemplate = (option) => {
  //     const blankAvatarSrc = 'assets/images/avatars/blank-ava.jpg'

  //     return (
  //         <div className="p-multiselect-representative-option">
  //             <img src={option?.avatar || blankAvatarSrc} alt={option?.name} className="filter-staff-avatar"
  //                 onError={(e) => e.target.src = blankAvatarSrc}
  //             />
  //             <span style={{ verticalAlign: 'middle', marginLeft: '.5em' }}>{option?.name}</span>
  //         </div>
  //     );
  // }
  // const renderTechnicianFilter = () => {
  //     // console.log('listTechnicians: ', listTechnicians);
  //     // console.log('selectedTechnicians: ', selectedTechnicians);
  //     return (
  //         <MultiSelect className="p-column-filter" display="chip"
  //             options={listTechnicians}
  //             value={technicianIds}
  //             onFocus={onOpenTechniciansSelectBox}
  //             onChange={onTechnicianFilterChange}
  //             itemTemplate={technicianItemTemplate}
  //             placeholder="All" optionLabel="name" optionValue="id" />
  //     );
  // }


  // Services filter
  const groupedItemTemplate = (option) => {
    return (
      <div className="p-d-flex p-ai-center service-item">
        <div>{option.name}</div>
      </div>
    );
  }
  // const serviceItemTemplate = (option) => {
  //     // const src = "showcase/demo/images/avatar/" + option.image;
  //     return (
  //         <div className="p-multiselect-representative-option">
  //             {/* <img alt={option?.name} src={src} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} width="32" style={{ verticalAlign: 'middle' }} /> */}
  //             <span style={{ verticalAlign: 'middle', marginLeft: '.5em' }}>{option?.name}</span>
  //         </div>
  //     );
  // }
  const renderServiceFilter = () => {
    return (
      <MultiSelect className="p-column-filter"
        options={listServices}
        value={serviceIds}
        onFocus={onOpenServicesSelectBox}
        onChange={onServiceFilterChange}
        // itemTemplate={serviceItemTemplate}
        placeholder="All" optionLabel="name" optionValue="id"
        optionGroupLabel="name" optionGroupChildren="list" optionGroupTemplate={groupedItemTemplate} />
    );
  }

  // Booking status filter
  const statusItemTemplate = (option) => {
    return <span className={classNames('appointment-badge', 'status-' + option)} style={{ borderRadius: '3px' }}>{option}</span>
  }
  const renderStatusFilter = () => {
    return (
      <Dropdown value={bookingStatus} options={bookingStatuses} onChange={onStatusFilterChange}
        itemTemplate={statusItemTemplate} showClear={bookingStatus}
        placeholder="Select a status" className="p-column-filter" />
    );
  }

  //==========================Define custom elements' variables==========================
  const header = renderHeader();
  const cusNameFilterElement = renderNameFilter();
  const dateRangeFilterElement = renderDateRangeFilter();
  // const technicianFilterElement = renderTechnicianFilter();    // tạm khóa technician
  const serviceFilterElement = renderServiceFilter();
  const statusFilterElement = renderStatusFilter();

  const tHeadColumnGroup = <ColumnGroup>
    <Row>
      {/* <Column selectionMode="multiple" style={{ width: '2.5rem' }} rowSpan={2} /> */}
      <Column header="ID" headerStyle={{ width: '3rem', textAlign: 'center' }} rowSpan={2}
        sortable sortField="id" />
      <Column header="Customer Info" headerStyle={{ width: '16%', textAlign: 'center' }}
        sortable sortField="name" />
      <Column header="Created At" headerStyle={{ textAlign: 'center' }} rowSpan={2}
        sortable sortField="createdDate" />
      <Column header="Appointment Time" colSpan={2} headerStyle={{ textAlign: 'center' }}
        sortable sortField="time" />
      {/* tạm khóa technician lại */}
      {/* <Column header="Technician" headerStyle={{ width: '15%', textAlign: 'center' }} /> */}
      {/* sortable sortField="technician.name" */}
      <Column header="Services" headerStyle={{ width: '23.5%', textAlign: 'center' }} />
      <Column header="Booking Status" headerStyle={{ width: '13%', textAlign: 'center' }}
        sortable sortField="status" />
      <Column header="" headerStyle={{ width: '4rem', textAlign: 'center' }} rowSpan={2} />
    </Row>
    <Row>
      <Column filter filterField="name" filterElement={cusNameFilterElement} />
      <Column filter filterElement={dateRangeFilterElement} colSpan={2} />
      {/* <Column filter filterElement={technicianFilterElement}
                // filterField="technician.name"    // chuyển qua filter phía BE rồi nên ko cần này nữa
                // tạm khóa technician lại
            /> */}
      <Column filter filterElement={serviceFilterElement} />
      <Column filter filterElement={statusFilterElement} />

    </Row>
  </ColumnGroup>
  // const loadingBody = <Loading />

  //======================================Paginator======================================
  const handleChangePage = (e) => {
    if (e === page || page === e?.target?.innerText - 1) return
    let newPage = e
    if (e.target?.innerText)
      newPage = parseInt(e.target.innerText) - 1
    setFilter(filterTypes.PAGE, newPage);

    // console.log('handleChangePage -- event: ', e);

    dispatchParams({
      type: actionTypes.CHANGE_PAGE,
      payload: {
        page: newPage,
        limit: limit
      }
    })
  };

  const handleChangeLimit = (event) => {
    setFilter(filterTypes.LIMIT, parseInt(event?.target?.value, 10));   // hoặc "event?.value" cũng đc
    setFilter(filterTypes.PAGE, 0);
    // setLimit(event?.target?.value)
    // setPage(0)

    // console.log('handleChangeLimit -- event: ', event);

    dispatchParams({
      type: actionTypes.CHANGE_PAGE,
      payload: {
        page: 0,
        limit: parseInt(event.target.value, 10)
      }
    })
  };

  const paginatorTemplate = {
    layout: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
    FirstPageLink: (options) => {
      return (
        <Button
          type="button"
          className={options.className}
          onClick={e => {
            options.onClick(e)
            handleChangePage(0)
          }}
          disabled={options.disabled}
        >
          <i className="pi pi-angle-double-left" />
          <Ripple />
        </Button>
      );
    },
    PrevPageLink: (options) => {
      return (
        <Button
          type="button"
          className={options.className}
          onClick={e => {
            options.onClick(e)
            handleChangePage(page - 1)
          }}
          disabled={options.disabled}
        >
          {/* <span className="p-p-3">Previous</span> */}
          <i className="pi pi-angle-left" />
          <Ripple />
        </Button>
      );
    },
    NextPageLink: (options) => {
      return (
        <Button
          type="button"
          className={options.className}
          onClick={e => {
            options.onClick(e)
            handleChangePage(page + 1)
          }}
          disabled={options.disabled}
        >
          {/* <span className="p-p-3">Next</span> */}
          <i className="pi pi-angle-right" />
          <Ripple />
        </Button>
      );
    },
    LastPageLink: (options) => {
      return (
        <Button
          type="button"
          className={options.className}
          onClick={e => {
            options.onClick(e)
            handleChangePage(totalPage - 1)
          }}
          disabled={options.disabled}
        >
          <i className="pi pi-angle-double-right" />
          <Ripple />
        </Button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span className={className} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }

      return (
        <Button
          type="button"
          className={options.className}
          // onClick={options.onClick}
          // onClick={handleChangePage}
          onClick={e => {
            options.onClick(e)
            handleChangePage(e)
          }}
        >
          {options.page + 1}
          <Ripple />
        </Button>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 }
        // { label: "All", value: options.totalRecords }
      ];
      return (
        <Dropdown
          value={limit}
          // value={options.value}
          options={dropdownOptions}
          // onChange={options.onChange}
          appendTo={document.body}
          onChange={handleChangeLimit}
        />
      );
    }
  };

  return (
    <div>
      <Toast ref={toast} />

      <div className="datatable-appointments">
        <DataTable ref={dt} value={appointmentData} dataKey="id"
          rowHover stripedRows  //scrollable scrollHeight="200px"
          className="p-datatable-sm p-datatable-appointments"
          header={header} headerColumnGroup={tHeadColumnGroup}
          // globalFilter={globalSearch}
          selection={selectedRow} selectionMode="single"
          onSelectionChange={(e) => setSelectedRow(e.value)} //cellSelection 
          onRowDoubleClick={() => setOpenDetailDialog(true)}  // Phải dùng double click để tránh mở 2 dialogs cùng 1 lúc
          emptyMessage="No appointments found"
          paginator paginatorTemplate={paginatorTemplate}
          first={0} rows={limit} lazy
          // rows={10} rowsPerPageOptions={[10, 25, 50]}
          currentPageReportTemplate="Showing {first}-{last} of {totalRecords} entries"
          // paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          totalRecords={totalElements}
          sortField={sortBy} sortOrder={direction === 'DESC' ? -1 : 1} onSort={handleSort}
        >
          {/* <Column selectionMode="multiple" style={{ width: '2.5rem' }} /> */}
          <Column field="id" body={idBodyTemplate} style={{ textAlign: 'center' }} />
          <Column field="name" body={cusNameBodyTemplate} />
          <Column body={createdDateBodyTemplate} style={{ textAlign: 'center' }} />
          <Column body={dateBodyTemplate} style={{ textAlign: 'center' }} />
          <Column body={timeBodyTemplate} style={{ textAlign: 'center' }} />
          {/* <Column field="technician" body={technicianBodyTemplate} />
                        // tạm khóa vì tất cả đều là anyone
                    */}
          <Column field="options" body={serviceBodyTemplate} />
          <Column field="status" body={statusBodyTemplate} style={{ textAlign: 'center' }} />
          <Column body={actionBodyTemplate} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
        </DataTable>
      </div>
      <ScrollTop />

      <UpdateStatusDialog
        openDialog={openUpdateDialog}
        setOpenDialog={setOpenUpdateDialog}
        setSelectedRow={setSelectedRow}
        appointment={selectedRow}
        refreshData={() => refreshData(params)}
        toast={toast}
      />    {/** setLoading={setLoading} */}
      <DetailDialog
        openDialog={openDetailDialog}
        setOpenDialog={setOpenDetailDialog}
        appointment={selectedRow}
      />
    </div>
  )
}

export default StyledTable
