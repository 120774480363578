import React, { useRef, useState } from 'react'
// import { useHistory } from 'react-router-dom'
import { Toast } from 'primereact/toast';
import { Loading, ContentContainer } from '../../components'
import { Head } from './components'
import { optionName } from './data'
import DefaultOption from './components/Body/DefaultOption'
import CustomOption from './components/Body/CustomOption'

function BookingTime() {
    const [option, setOption] = useState(optionName.custom)
    const toast = useRef(null);

    return (
        <ContentContainer pageName="Booking Time" tabName={option}>
            <Toast ref={toast} />

            <Head option={option} setOption={setOption} />

            {option === optionName.default ? (
                <DefaultOption option={option} toast={toast} />
            ) : (
                <CustomOption option={option} toast={toast} />
            )}
        </ContentContainer>
    )
}

export default BookingTime
