import React, { useEffect, useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { useApp } from '../../../../hooks/AppContext';
import { SuggestedNameWrapper, SuggestedName } from './AddAccountDialogItems'
import './dialog.css'

function AddAccountDialog(props) {
  const { open, setOpen, data, toastMsg } = props    //, setLoading
  const { request4RefreshToken } = useApp()

  const [listNames, setListNames] = useState([])
  const [name, setName] = useState('')
  const [points, setPoints] = useState(0)

  const typingTimeOutRef = useRef(null)

  const inputPhoneRef = useRef(null)

  const defaultValues = { phone: '', name: '', points: 0 }

  const { control, formState: { errors, dirtyFields },
    getValues, reset, handleSubmit } = useForm({
      mode: 'onChange',
      defaultValues: defaultValues,
    });

  const handleGetCustomerInfo = () => {
    // Call API to check whether existed or not. 
    // If account already existed, return name[] & current points.

    setListNames(['Gâu', 'Gia', 'Rikkei', 'Rikkeisoft', 'Toshiba', 'Hitachi', 'Panasonic',
      'Sharp', 'LocknLock'])
  }

  useEffect(() => {
    if (typingTimeOutRef.current) {
      clearTimeout(typingTimeOutRef.current)
    }

    // console.log("getValues('phone'): ", getValues('phone'));

    if (getValues('phone').trim()) {
      typingTimeOutRef.current = setTimeout(() => {
        handleGetCustomerInfo()
      }, 1500);
    } else {
      setListNames([])
    }
  }, [getValues('phone')]);

  useEffect(() => {

  }, [points])

  const handleSubmitForm = data => {
    const account = {
      phone: data?.phone,
      name: data?.name,
      reward: points,
      // note: data?.note,
      // rewardProgram: data?.rewardProgram,
    }

    // console.log('form data: ', data);
    // console.log('account: ', account);

    // Call API (đang chờ)
  }

  const handleFocusPhoneInput = (event) => {
    inputPhoneRef.current?.setSelectionRange(0, 0)

    // console.log('inputPhoneRef: ', inputPhoneRef.current);
  }

  const handleClose = () => {
    reset()
    setName('')
    setPoints(0)
    setListNames([])
    setOpen(false)
  }

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error d-block mt-1">{errors[name].message}</small>
  };

  const renderFooter = () => {
    return (
      <div>
        <Button label="Cancel" icon="pi pi-times" onClick={handleClose}
          className="p-button-outlined p-button-plain p-button-sm" />
        <Button label="Save" icon="pi pi-check" autoFocus
          type='submit' onClick={handleSubmit(handleSubmitForm)}
          disabled={!dirtyFields.phone || !dirtyFields.points}
          className="p-button-success p-button-sm" />
      </div>
    );
  }

  return (
    <Dialog visible={open} onHide={handleClose}
      dismissableMask
      header="Add New Rewards Account" footer={renderFooter()}
      className='dialog-rewards' style={{ width: '25rem' }}
    >
      <form className="p-fluid" style={{ width: '100%' }}>
        {/* Phone */}
        <div className="row dialog-row-info d-flex align-items-center">
          <div className="col-sm-4">
            <span className="dialog-content-label">Phone number</span>
            <span className="dialog-content-required">*</span>
          </div>
          <div className="field col-sm-8">
            <Controller name="phone" control={control}
              rules={{ required: 'Phone number is required.' }}
              render={({ field, fieldState }) => (
                <InputMask id={field.phone} mask="(999) 999-9999"
                  autoFocus inputRef={inputPhoneRef}
                  onFocus={handleFocusPhoneInput}
                  {...field}
                  className={classNames('w-100', { 'p-invalid': fieldState.invalid })}
                />
              )} />
            {getFormErrorMessage('phone')}
          </div>
        </div>

        {/* Name */}
        <>
          <div className="row dialog-row-info d-flex align-items-center">
            <div className="col-sm-4">
              <span className="dialog-content-label">Name</span>
            </div>
            <div className="field col-sm-8">
              <Controller name="name" control={control} render={({ field }) => (
                <InputText id={field.name} {...field}
                  value={name}
                  onChange={(e) => setName(e.value)}
                  className="w-100"
                />
              )} />
            </div>
          </div>
          <SuggestedNameWrapper>
            {listNames?.length > 0 &&
              listNames?.map((savedName, index) => (
                <SuggestedName
                  key={index}
                  onClick={() => setName(savedName)}
                >
                  {savedName}
                </SuggestedName>
              ))}
          </SuggestedNameWrapper>
        </>

        {/* Points */}
        <div className="row dialog-last-row d-flex align-items-center">
          <div className="col-sm-4">
            <span className="dialog-content-label">Reward points</span>
            <span className="dialog-content-required">*</span>
          </div>
          <div className="field col-sm-8 ">
            <Controller name="points" control={control}
              rules={{ required: 'Reward points is required.' }}
              render={({ field }) => (
                <InputNumber id={field.points} {...field}
                  min={0} locale="en-US" prefix="+"
                  value={points} onValueChange={e => setPoints(e.value)}
                  style={{ width: '8rem' }}
                />
              )} />
            {getFormErrorMessage('points')}
          </div>
        </div>
      </form>
    </Dialog>
  )
}

export default AddAccountDialog
