import React from 'react'
import { ContentContainer } from '../../components'

function SalonProfile() {
    return (
        <ContentContainer pageName="Salon's Profile">
            Coming soon...
        </ContentContainer>
    )
}

export default SalonProfile