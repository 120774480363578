import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { routerData } from '../routers/routerData'
import {NotFound} from '../pages'

function Main() {
    // const [login, dashboard, appointments, services, technicians, gallery, bookingTime, salonProfile] = routerData
    // const pages = [appointments, bookingTime]

    return (
        <div className="content-wrapper">
            {/* Switch Router các trang ở đây */}
            <Switch>
                {routerData.map(page => {
                    // if (page?.name === 'Dashboards')
                    //     return <Route exact path={page?.path} component={page?.component} key={page?.path} />
                    // else
                    // if (page?.children && page?.children?.length > 0) {
                    //   page?.children?.map(childPage => (
                    //     <Route path={childPage?.path} component={childPage?.component} key={childPage?.path} />
                    //   ))
                    // }

                    return <Route path={page?.path} component={page?.component} key={page?.path} />
                })}
                <Route path='/:otherPaths' component={NotFound} />
            </Switch>
        </div>
    )
}

export default Main
