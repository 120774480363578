import React from 'react'
import { ContentContainer } from '../../components'

function Services() {
    return (
        <ContentContainer pageName="Services">
            Coming soon...
        </ContentContainer>
    )
}

export default Services