import { BlankAvatar } from '../../../assets/images'
import moment from 'moment-timezone'

const getTechnicianObject = (rowData) => {
    let newRowData = {
        ...rowData,
        technician: {
            id: rowData?.technicianId ? rowData?.technicianId : -1,
            name: rowData?.technicianName ? rowData?.technicianName : 'Anyone',
            avatar: rowData?.avatar ? rowData?.avatar : BlankAvatar
        }
    }
    delete newRowData?.technicianId
    delete newRowData?.technicianName
    delete newRowData?.avatar

    return newRowData
}

const _checkExistedInArray = (arr, obj) => {
    return arr.some(item => item.label === obj.label)
}

const getListServices = (rowData) => {
    const options = [...rowData?.options]
    let serviceGroups = []
    let aGroup = null
    let services = []
    let aService = null
    let flavors = []
    let aFlavor = null

    let key1 = 0
    let key2 = 0
    let key3 = 0
    let groupName = ''
    let serviceAndFlavor = ''
    let serviceName = ''
    let flavorName = ''

    // console.log('list services from API: ', options);

    /* Ex: Dipping powder (NexGen) - Artificial Nails                   (service - group)
        
            "Ultimate Spa Pedicure(Lavender & Mint) - Pedicures",       (service(option1) - group)
            "Ultimate Spa Pedicure(Aloe Vera) - Pedicures"              (service(option2) - group)
    */
    for (let i1 = 0; i1 < options.length; i1++) {
        aGroup = null
        aService = null
        aFlavor = null
        groupName = ''
        serviceName = ''
        flavorName = ''

        groupName = options[i1].split('- ')[1];

        serviceAndFlavor = options[i1].split(' - ')[0]

        if (serviceAndFlavor.includes('(') && !serviceAndFlavor.includes(' (')) {
            serviceName = serviceAndFlavor.split('(')[0]
            flavorName = serviceAndFlavor.substring(serviceAndFlavor.indexOf('(') + 1, serviceAndFlavor.indexOf(')'))
        } else {
            serviceName = serviceAndFlavor
        }

        aGroup = { label: groupName }
        aService = { label: serviceName }
        if (flavorName) {
            aFlavor = { label: flavorName }
        }

        // console.log('aFlavor --- before if: ', aFlavor?.label);

        if (!_checkExistedInArray(serviceGroups, aGroup)) {  // Chưa
            /* Nếu group chưa tồn tại thì:
                B1: Add flavor vô service (nếu có)
                B2: Add service vô group
                B3: Add group mới vô mảng && tăng key
            */
            services = []
            key1 = serviceGroups.length
            key2 = 0
            aGroup = { ...aGroup, key: `${key1}` }
            aService = { ...aService, key: `${key1}-${key2}` }

            if (aFlavor) {
                aFlavor = { ...aFlavor, key: `${key1}-${key2}-${key3}` }
                flavors.push(aFlavor)
                aService = { ...aService, children: flavors }
            }
            services.push(aService)
            aGroup = { ...aGroup, children: services }
            serviceGroups.push(aGroup)
        }
        else {                                                // Rồi
            /* Nếu group đã tồn tại thì:    (này ko chuẩn lắm đâu,chưa sửa)
                B1: Check xem service đã tồn tại hay chưa
                B2.a: Add flavor vô service (nếu có) --> Push service mới vào list services --> Update vô group
                B2.b: Add service vô group
                B3: Add group mới vô mảng && tăng key
            */
            if (!_checkExistedInArray(services, aService)) {   // Chưa
                flavors = []
                key2 = services.length
                key3 = 0
                aService = { ...aService, key: `${key1}-${key2}` }

                if (aFlavor) {
                    aFlavor = { ...aFlavor, key: `${key1}-${key2}-${key3}` }
                    flavors.push(aFlavor)
                    aService = { ...aService, children: flavors }
                }
                services.push(aService)
            }
            else {                                            // Rồi
                if (aFlavor) {
                    // console.log('flavors: ', flavors);
                    // console.log('aFlavor: ', aFlavor.label);

                    if (!_checkExistedInArray(flavors, aFlavor)) { // Chưa
                        key3 = flavors.length
                        aFlavor = { ...aFlavor, key: `${key1}-${key2}-${key3}` }

                        flavors.push(aFlavor)
                        aService = { ...aService, children: flavors }
                    }   // else Rồi thì bỏ qua
                }
            }
        }
    }

    let newRowData = {
        ...rowData,
        options: [...serviceGroups]
    }

    // console.log('list services after preparation: ', newRowData?.options);

    return newRowData
}

/* 
    This function needs to do 2 jobs:
    - convert 3 separated fields techId, techName and avatar into 1 object named "technician"
    - Split field "options" into service group, service name and service options
*/
export const refractorTableData = (tableData) => {
    // console.log('Before refractor: ', tableData);

    let newData = []
    tableData?.map(row => {
        // The time which retrived from Back-end is UTC time, so we need to convert to the timezone at salon
        // row = { ...row, time: convertToTimezone(row?.time, 'America/Chicago', 'YYYY-MM-DD HH:mm:ss') }
        row = { ...getTechnicianObject(row) }
        row = { ...getListServices(row) }

        // update rowData with new datetime into new list
        newData.push(row)
    })
    tableData = [...newData]

    // console.log('');
    // console.log('After refractor: tableData = ', tableData);

    return tableData
}

export const refractorServicesList = (originalList) => {
    let newList = []
    let newGroup = {}

    originalList?.map(group => {
        newGroup = { id: group?.id, name: group?.name, list: [] }
        group?.list?.map(service => {
            if ([23, 24].includes(service?.id)) {
                service = {
                    ...service,
                    name: service?.name?.substring(0, service?.name?.indexOf(' (') + 1)
                }
            }
            newGroup?.list?.push({ id: service?.id, name: service?.name })
        })
        newList.push(newGroup)
    })
    return newList
}

export const getDateRangeInitialValues = (start, end) => {
  return (start && end) ? [
    moment.tz(start, 'America/Chicago').toDate(),   // format('MM/DD/YYYY')
    moment.tz(end, 'America/Chicago').toDate()
  ] : null
}