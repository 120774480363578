import React from 'react'
import LoginForm from './LoginForm'
import { LoginBg } from '../../assets/images'
import './Login.css'

function Login() {
    return (
        <div className="login-container">
            <img src={LoginBg} />
            <h1 className="brand-name">dELUXE nAILS <span className="brand-name__and-symbol">&amp;</span> sPA</h1>
            <div className="login-modal">
                <LoginForm />
            </div>
        </div>
    )
}

export default Login