import React, { useState } from 'react'
import { Button } from 'primereact/button'

import {
  MyFieldset, Legend, Row, Title, Content
} from './ProfileItems'
import { parseDateUTCToString } from '../../../../../../utils/DateTime'

function Profile(props) {
  const { data, inputsValue, setInputsValue, toastMsg } = props

  const [isEditing, setIsEditing] = useState({
    phone: false,
    email: false,
    birthdate: false,
  })

  const onChangeInputs = (key, value) => {
    setInputsValue({
      ...inputsValue, [key]: value
    })
  }

  const onChangeIsEditing = (key, value) => {
    setIsEditing({
      ...isEditing, [key]: value
    })
  }

  const handleSaveChanges = field => {
    onChangeIsEditing(field, false)

    // Call API ở đây


  }

  return (
    <MyFieldset legend={<Legend>Profile</Legend>}>
      {/* ID */}
      <Row>
        <Title>ID</Title>
        <Content>{data?.id}</Content>
      </Row>

      {/* Name */}
      <Row>
        <Title>Full name</Title>
        <Content>{data?.fullName}</Content>
      </Row>

      {/* Phone */}
      <Row>
        <Title>Phone No.</Title>

        <Content className='d-flex justify-content-between align-items-start'
          style={{ height: 'fit-content' }}
        >
          <div className='w-100 d-flex'>
            {isEditing.phone ? (
              <input style={{ width: '90%' }}
                value={inputsValue?.phone}
                onChange={e => onChangeInputs('phone', e?.target?.value)} />
            ) : data?.phone
            }
          </div>

          <div>
            {!isEditing?.phone ? (
              <Button icon="pi pi-pencil"
                className="p-button-rounded p-button-text p-0"
                style={{ width: '24px', height: '24px' }}
                onClick={() => onChangeIsEditing('phone', true)}
              />
            ) : (
              <Button icon="pi pi-check"
                className="p-button-rounded p-button-text p-button-success p-0"
                style={{ width: '24px', height: '24px' }}
                onClick={() => handleSaveChanges('phone')}
              />
            )}
          </div>
        </Content>
      </Row>

      {/* Email */}
      <Row>
        <Title>Email</Title>

        <Content className='d-flex justify-content-between align-items-start'
          style={{ height: 'fit-content' }}
        >
          <div className='w-100 d-flex'>
            {isEditing.email ? (
              <input style={{ width: '90%' }}
                type='email'
                value={inputsValue?.email}
                onChange={e => onChangeInputs('email', e?.target?.value)} />
            ) : data?.email
            }
          </div>

          {inputsValue?.email && (
            <div>
              {!isEditing.email ? (
                <Button icon="pi pi-pencil"
                  className="p-button-rounded p-button-text p-0"
                  style={{ width: '24px', height: '24px' }}
                  onClick={() => onChangeIsEditing('email', true)}
                />
              ) : (
                <Button icon="pi pi-check"
                  className="p-button-rounded p-button-text p-button-success p-0"
                  style={{ width: '24px', height: '24px' }}
                  onClick={() => handleSaveChanges('email')}
                />
              )}
            </div>
          )}
        </Content>
      </Row>

      {/* DoB */}
      <Row>
        <Title>Birth date</Title>

        <Content className='d-flex justify-content-between align-items-start'
          style={{ height: 'fit-content' }}
        >
          <div className='w-100 d-flex'>
            {isEditing.birthdate ? (
              <input style={{ width: '90%' }}
                value={inputsValue?.birthdate}
                onChange={e => onChangeInputs('birthdate', e?.target?.value)} />
            ) :
              inputsValue?.birthdate
                ? parseDateUTCToString(data?.birthdate, 'MM / DD')
                : ''
            }
          </div>

          {inputsValue?.birthdate && (
            <div>
              {!isEditing.birthdate ? (
                <Button icon="pi pi-pencil"
                  className="p-button-rounded p-button-text p-0"
                  style={{ width: '24px', height: '24px' }}
                  onClick={() => onChangeIsEditing('birthdate', true)}
                />
              ) : (
                <Button icon="pi pi-check"
                  className="p-button-rounded p-button-text p-button-success p-0"
                  style={{ width: '24px', height: '24px' }}
                  onClick={() => handleSaveChanges('birthdate')}
                />
              )}
            </div>
          )}
        </Content>
      </Row>


      {/* Save trên từng field rồi, ko cần btnSave chung ở đây nữa */}
      {/* {phone !== data?.phone && (
        <div className='d-flex justify-content-end mt-2 mb-2'>
          <BtnSave icon="pi pi-check" label='Save'
            className='p-button-success'
            onClick={handleUpdatePoints}
            disabled={point === 0 || listDisabled.includes(updateType)}
          />
        </div>
      )} */}
    </MyFieldset>
  )
}

export default Profile