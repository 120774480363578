import { actionTypes as Action } from './filter-types'

export const BookingReducer = (params, action) => {
    const { type, payload } = action

    switch (type) {
        case Action.FILTER_CUS_NAME:
            return { ...params, page: 0, name: payload }

        case Action.FILTER_FROM_DATE:
            return { ...params, page: 0, start: payload }

        case Action.FILTER_TO_DATE:
            return { ...params, page: 0, end: payload }

        case Action.FILTER_TECHNICIANS:
            return { ...params, page: 0, technicianIds: payload }

        case Action.FILTER_SERVICES:
            return { ...params, page: 0, serviceIds: payload }

        case Action.FILTER_BOOKING_STATUS:
            return { ...params, page: 0, status: payload }

        case Action.SEARCH:
            return { ...params, page: 0, key: payload }

        case Action.SORT:
            return { ...params, direction: payload.direction, sortBy: payload.sortBy }

        case Action.CHANGE_PAGE:
            return { ...params, page: payload.page, limit: payload.limit }

        default:
            return params
    }
}