import React, { useEffect, useRef, useState } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { ToggleButton } from 'primereact/togglebutton'
import { Button } from 'primereact/button';
import { noteContent } from '../../data'
import { getAllSlots, getSlotsByDay, updateAvailableSlots, applyDefault } from '../../../../services/BookingApi'
import moment from 'moment-timezone'
import { useApp } from '../../../../hooks/AppContext';
import { getMinMaxItem } from '../../../../utils/Arrays'
import { useHistory } from 'react-router-dom';
import './TimePicker.css'

function TimePicker({ option, day, dayId, toast }) {    // setDay,
    const history = useHistory()
    const { request4RefreshToken } = useApp()

    const [timeSlots, setTimeSlots] = useState([])  // slots loaded from API
    const defaultTimeFrame = useRef([])
    // const [timeSlotsForAll, setTimeSlotsForAll] = useState([])  // default slots for option "All". This is just for better UX    // A Gia nói cái này UX tệ chứ ko better
    let timeSlotIds = []
    const [listHours, setListHours] = useState([])
    let listHours4Display = []
    const [openSlot, setOpenSlot] = useState(getMinMaxItem(timeSlots).min)  // timeSlots[0]?.value
    const [closeSlot, setCloseSlot] = useState(getMinMaxItem(timeSlots).max)    // timeSlots[timeSlots.length - 1]?.value
    const [checkedTimes, setCheckedTimes] = useState([]);     // {id: -1, checked: false}
    const inititalListSlotsIds = useRef(null)
    // const openChanged = useRef(false)
    // const closeChanged = useRef(false)
    // const isEndOfSlot = useRef(null)

    let loadingHours = true
    const loadAllSlots = () => {
        getAllSlots().then(res => {
            if (loadingHours) {
                setListHours(res)
            }
        }).catch(error => {
            if (error?.response?.status === 403 && error?.response?.data?.error_message?.includes('The Token has expired')) {
                request4RefreshToken(loadAllSlots)
            } else if (error?.response?.status === 403 && error?.response?.data?.error === 'Forbidden') {
                history.replace('/')
                // alert('You are not allowed to use this feature. Please login again.')
            } else {
                console.log('Error at loadAllSlots(): ', error);
            }
        })
    }

    let loadingTimeSlots = true
    const loadSlotsByDay = (day) => {
        getSlotsByDay(day).then(res => {
            if (loadingTimeSlots) {
                setTimeSlots(res)
                inititalListSlotsIds.current = res      // .map(timeSlot => timeSlot?.id)

                if (day === 0) { // Option "Default time frame"
                    defaultTimeFrame.current = res
                    // let slotsForOptionAll = res?.filter(slot => slot?.value >= 900 && slot?.value <= 1930)
                    // // console.log('slotsForOptionAll = ', slotsForOptionAll);
                    // setTimeSlotsForAll(slotsForOptionAll)
                    // setOpenSlot(slotsForOptionAll[0]?.value)
                    // setCloseSlot(slotsForOptionAll[slotsForOptionAll.length - 1]?.value)
                }
                // else {
                setCheckedTimes([])
                setOpenSlot(getMinMaxItem(res).min)    // res[0]?.value
                setCloseSlot(getMinMaxItem(res).max)   // res[res.length - 1]?.value
                // openChanged.current = false
                // closeChanged.current = false
                // }
            }
        }).catch(error => {
            console.log('Error at loadSlotsByDay(): ', error);
        })
    }

    useEffect(() => {
        loadAllSlots()
        return () => {
            loadingHours = false
        }
    }, [])

    useEffect(() => {
        if ([0, 1, 2, 3, 4, 5, 6, 7].includes(dayId)) {
            loadSlotsByDay(dayId)
        } else {
            loadSlotsByDay(day)
        }
        // setCheckedTimes([])
        // openChanged.current = false
        // closeChanged.current = false

        return () => {
            loadingTimeSlots = false
        }
    }, [day])

    const handleSelectTimeSlot = (e, slotId) => {
        // isEndOfSlot.current = true;
        if (![0, 1, 2, 3, 4, 5, 6, 7].includes(dayId)) {
            if (moment(day).isBefore(moment(new Date()).format('YYYY-MM-DD')))
                return;
        }

        const listCheckedTimes = [...checkedTimes]
        const indexCheck = listCheckedTimes?.indexOf(slotId)
        const indexAvailable = timeSlotIds?.indexOf(slotId)
        // console.log('index = ', indexAvailable)
        // console.log('event = ', e)

        // Đc tick sẵn chưa
        if ((indexAvailable !== -1) && (e.value === false)) {
            setTimeSlots(timeSlots.filter(time => time?.id !== slotId))
        }
        if (indexCheck !== -1) {
            listCheckedTimes.splice(indexCheck, 1)
        }
        if (e.value === true) {
            listCheckedTimes.push(slotId)
        }

        console.log('listCheckedTimes = ', listCheckedTimes);
        setCheckedTimes(listCheckedTimes)
        // setAppointment({ ...appointment, dateTime: slot })
    }
    // let checkedSlotIds = checkedTimes.map(time => time?.id)
    timeSlotIds = timeSlots.map(timeSlot => timeSlot?.id);
    listHours4Display = listHours.filter(hour => (hour?.value >= openSlot && hour?.value <= closeSlot))

    const _filterOutOfRangeIds = () => {
        const smallerIds = listHours?.filter(hour => hour?.value < openSlot).map(slot => slot?.id)
        const greaterIds = listHours?.filter(hour => hour?.value > closeSlot).map(slot => slot?.id)
        const outRangeIds = smallerIds.concat(greaterIds)
        // console.log('outRangeIds = ', outRangeIds);

        let availableSlots = checkedTimes.concat(timeSlotIds)
        // console.log('Before availableSlots = ', availableSlots);
        availableSlots = availableSlots.filter(slot => !outRangeIds?.includes(slot))
        // console.log('After availableSlots = ', availableSlots);

        return availableSlots
    }

    const handleSave = () => {
        let availableSlots = _filterOutOfRangeIds()
        // availableSlots = availableSlots.filter(slot => _listOutOfRangeIds()?.includes(slot))
        console.log('availableSlots = ', availableSlots);

        const data = {
            name: [0, 1, 2, 3, 4, 5, 6, 7].includes(dayId) ? dayId : day,
            slotIds: availableSlots
        }
        updateAvailableSlots(data).then(res => {
            if ([0, 1, 2, 3, 4, 5, 6, 7].includes(dayId)) {
                loadSlotsByDay(dayId)
            } else {
                loadSlotsByDay(day)
            }
            setCheckedTimes([])
            toast.current.show({
                severity: 'success',
                summary: 'Successful',
                detail: 'Updated booking time successfully',
                life: 3000
            });
        }).catch(error => {
            console.log('error?.response = ', error?.response);
            if (error?.response?.status === 403 && error?.response?.data?.error_message?.includes('The Token has expired')) {
                request4RefreshToken(handleSave)
            } else if (error?.response?.status === 403 && error?.response?.data?.error === 'Forbidden') {
                history.replace('/')
                // alert('You are not allowed to use this feature. Please login again.')
            } else {
                console.log('Error at updateAvailableSlots(): ', error);
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: 'Updated booking time failed',
                    life: 3000
                });
            }
        })
        // console.log('Save: ', openSlot, closeSlot, timeSlots[checkedTime?.id]?.label,);
    }

    const handleCancel = () => {
        setCheckedTimes([])
        setTimeSlots(inititalListSlotsIds.current)
        setOpenSlot(getMinMaxItem(inititalListSlotsIds.current).min)    // inititalListSlotsIds.current[0]?.value
        setCloseSlot(getMinMaxItem(inititalListSlotsIds.current).max)   // inititalListSlotsIds.current[inititalListSlotsIds.current?.length - 1]?.value

        // if (![0, 1, 2, 3, 4, 5, 6, 7].includes(dayId)) {
        //     // setDay(new Date())
        //     setOpenSlot(timeSlots[0]?.value)
        //     setCloseSlot(timeSlots[timeSlots.length - 1]?.value)
        // } else {
        //     setDay(]?.value)

        //     // let slotsForOptionAll = timeSlots?.filter(slot => slot?.value >= 900 && slot?.value <= 1930)
        //     // console.log('slotsForOptionAll = ', slotsForOptionAll);
        //     setTimeSlotsForAll(timeSlotsForAll)
        //     setOpenSlot(timeSlotsForAll[0]?.value)
        //     setCloseSlot(timeSlotsForAll[timeSlotsForAll.length - 1]?.value)
        // }
    }

    const handleApplyDefault = () => {
        const data = [0, 1, 2, 3, 4, 5, 6, 7].includes(dayId) ? dayId : day
        applyDefault(data).then(res => {
            if ([0, 1, 2, 3, 4, 5, 6, 7].includes(dayId)) {
                loadSlotsByDay(dayId)
            } else {
                loadSlotsByDay(day)
            }
            setCheckedTimes([])
            toast.current.show({
                severity: 'success',
                summary: 'Successful',
                detail: 'Applied default time frame successfully',
                life: 3000
            });

            console.log('Apply default: ', res);
        }).catch(error => {
            console.log('error?.response = ', error?.response);
            if (error?.response?.status === 403 && error?.response?.data?.error_message?.includes('The Token has expired')) {
                request4RefreshToken(handleApplyDefault)
            } else if (error?.response?.status === 403 && error?.response?.data?.error === 'Forbidden') {
                history.replace('/')
                // alert('You are not allowed to use this feature. Please login again.')
            } else {
                console.log('Error at updateAvailableSlots(): ', error);
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: 'Applied default time frame failed',
                    life: 3000
                });
            }
        })
        // setCheckedTimes([])
        // setTimeSlots(defaultTimeFrame.current)
        // setOpenSlot(getMinMaxItem(defaultTimeFrame.current).min)    // defaultTimeFrame.current[0]?.value
        // setCloseSlot(getMinMaxItem(defaultTimeFrame.current).max)   // defaultTimeFrame.current[defaultTimeFrame.current?.length - 1]?.value
    }

    const _isListTimeSlotsModified = () => {
        const originArr = inititalListSlotsIds.current?.map(timeSlot => timeSlot?.id)
        const currentArr = _filterOutOfRangeIds()
        // console.log('currentArr = ', currentArr);
        // console.log('originArr = ', originArr);

        if (currentArr?.length !== originArr?.length)
            return true
        else {
            for (let i = 0; i < originArr?.length; i++) {
                if (currentArr[i] !== originArr[i]) {
                    return true
                }
            }
            return false
        }
    }

    const _isDisable = () => {
        // console.log('isDisabled breakdown = ', checkedTimes?.length > 0, _isListTimeSlotsModified());   //, openChanged.current, closeChanged.current
        // console.log('isDisabled = ', checkedTimes?.length < 1 && !_isListTimeSlotsModified() && !openChanged.current && !closeChanged.current);

        if (checkedTimes?.length > 0 || _isListTimeSlotsModified()) {   // || openChanged.current || closeChanged.current
            if (_filterOutOfRangeIds()?.length === 0) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    // console.log('_isDisable() = ', _isDisable());

    return (
        <div className="time-picker">
            <div className="time-picker__note">
                <span className="note__label">
                    <i className="fas fa-exclamation-circle"></i>
                </span>
                {/* <span className="note__label">* Note:</span> */}
                <div className="note__content">{noteContent(option, day)}</div>
            </div>
            <div className="time-picker__from-to">
                <span className="from-to__label">Booking time</span>
                {/* <div className="from-to__title">
                    <span className="from-to__title-label">Booking time</span>
                    {![0, 1, 2, 3, 4, 5, 6, 7].includes(dayId) &&
                        <span className="from-to__title-selected-date">
                            {moment(day).format('MMM DD, YYYY')}
                        </span>
                    }
                </div> */}
                <div className="from-to__content">
                    <div className="time-picker__from-to-child">
                        <span className="from-to-child__label">Open at</span>
                        <div className="from-to__select">
                            <Dropdown value={openSlot} options={listHours}
                                onChange={e => {
                                    setOpenSlot(e.value)
                                    // openChanged.current = true
                                }}
                                disabled={[0, 1, 2, 3, 4, 5, 6, 7].includes(dayId) ? false :
                                    (moment(day).isBefore(moment(new Date()).format('YYYY-MM-DD')))}
                                optionLabel="name" placeholder="Open Hour" />
                        </div>
                    </div>
                    <div className="time-picker__from-to-child">
                        <span className="from-to-child__label">Close at</span>
                        <div className="from-to__select">
                            <Dropdown value={closeSlot} options={listHours?.filter(hour => hour?.value > openSlot)}
                                onChange={e => {
                                    setCloseSlot(e.value)
                                    // closeChanged.current = true
                                }}
                                disabled={[0, 1, 2, 3, 4, 5, 6, 7].includes(dayId) ? false :
                                    (moment(day).isBefore(moment(new Date()).format('YYYY-MM-DD')))}
                                // disabled={listHours?.map(hour => hour?.value < openSlot)}
                                // optionDisabled={listHours?.map(hour => hour?.value < openSlot)}
                                optionLabel="name" placeholder="Open Hour" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="time-picker__slots">
                <span className="slots__label">Opening slots</span>
                <span className="slots__counter">({_filterOutOfRangeIds()?.length} slots)</span>
                <div className="slots__info">{/* icon */}{/* tooltip */}</div>
                <div className="slots__hours">
                    {listHours4Display?.map(slot => (
                        <div className="button-wrap" key={slot?.id}>
                            {((timeSlotIds.includes(slot?.id) || checkedTimes.includes(slot?.id)) &&
                                (slot?.value >= openSlot && slot?.value <= closeSlot)) &&
                                <div className={`button-wrap__icon ${[0, 1, 2, 3, 4, 5, 6, 7].includes(dayId) ? null :
                                    (moment(day).isBefore(moment(new Date()).format('YYYY-MM-DD'))) && 'button-wrap__icon--disabled'
                                    }`}>
                                    <i className="fas fa-check-circle" />
                                </div>
                            }
                            <ToggleButton
                                key={slot?.id}
                                onLabel={slot?.name}
                                offLabel={slot?.name}
                                onChange={(e) => handleSelectTimeSlot(e, slot?.id)}
                                className={`p-button-outlined slots-hour__button 
                                    ${timeSlotIds.includes(slot?.id) && `slots-hour__button--active`}
                                    ${checkedTimes.includes(slot?.id) && `slots-hour__button--checked`}
                                    ${[0, 1, 2, 3, 4, 5, 6, 7].includes(dayId) ? null :
                                        (moment(day).isBefore(moment(new Date()).format('YYYY-MM-DD'))) && 'slots-hour__button--disabled'
                                    }
                                `}
                                // ${(slot?.value < openSlot || slot?.value > closeSlot) && 'slots-hour__button--disabled'}
                                checked={timeSlotIds.includes(slot?.id) || checkedTimes.includes(slot?.id)}
                            />
                        </div>
                    ))}
                    {/* <br />
                    {day} */}
                    {/* <div className="slots-hour__button"></div> */}
                </div>
            </div>

            <div className="time-picker__buttons">
                {/* {moment(day).isSameOrAfter(moment(new Date()).format('YYYY-MM-DD')) && (
                    <> */}
                {[1, 2, 3, 4, 5, 6, 7].includes(dayId) ? (
                    <Button label="Apply default time frame" onClick={handleApplyDefault}
                        className="p-button-outlined p-button-sm time-picker__button-default" />
                ) : (
                    <>
                        {(dayId !== 0 && moment(day).isSameOrAfter(moment(new Date()).format('YYYY-MM-DD'))) &&
                            <Button label="Apply default time frame" onClick={handleApplyDefault}
                                className="p-button-outlined p-button-sm time-picker__button-default" />
                        }
                    </>
                )}
                {([0, 1, 2, 3, 4, 5, 6, 7].includes(dayId) ||
                    (![0, 1, 2, 3, 4, 5, 6, 7].includes(dayId) && moment(day).isSameOrAfter(moment(new Date()).format('YYYY-MM-DD')))) &&
                    <>
                        <Button label="Cancel" icon="pi pi-times" onClick={handleCancel}
                            // disabled={[0, 1, 2, 3, 4, 5, 6, 7].includes(dayId) ? false :
                            //     (moment(day).isBefore(moment(new Date()).format('YYYY-MM-DD')))}
                            className="p-button-outlined p-button-plain p-button-sm time-picker__button-cancel" />
                        <Button label="Save" icon="pi pi-check" onClick={handleSave}    // autoFocus
                            disabled={_isDisable()}
                            // disabled={checkedTimes?.length < 1 && !_isListTimeSlotsModified() && !openChanged.current && !closeChanged.current}
                            // disabled={checkedTimes?.length < 1 ? (checkedTimes?.length < 1) : (!isListTimeSlotsModified())}
                            className="p-button-success p-button-sm" />
                    </>
                }
                {/* </>
                )} */}
            </div>
        </div>
    )
}

export default TimePicker
