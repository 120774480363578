const BASE_URL = process.env.REACT_APP_BASE_URL

export default {
    BASE_URL,
    LOGIN: '/login',
    REFRESH_TOKEN: '/token/refresh',
    APPOINTMENTS: '/admin/appointments',
    SERVICES: '',
    OPENING_TIME: '/admin/time',
    OPENING_SLOTS: '/admin/slot',
    REWARDS: '/promotions',
    CUSTOMER: '/checkIn-Out',
    SOCKET_URL: `${BASE_URL}/ws-message`
}