import { default as Api } from './ApiConfig'
import ENDPOINT from '../config/endpoints'

export async function getNumberOfAppointmentByStatus(status) {
    const response = await Api.get(`${ENDPOINT.APPOINTMENTS}/status?status=${status}`)
    return response
    // return (await response.data)     // đã config ".data" bên file ApiConfig rồi nên ở đây ko cần nữa
}

// export async function refreshToken() {
//     return await Api.get(`${ENDPOINT.REFRESH_TOKEN}`)
// }