import React from 'react'
import { ContentContainer } from '../../components'

function Gallery() {
    return (
        <ContentContainer pageName="Gallery">
            Coming soon...
        </ContentContainer>
    )
}

export default Gallery