import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Row } from 'primereact/row'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { parseDateUTCToString } from '../../../../../../../utils/DateTime'
import { tableHistoryColumns } from '../../../../../prepareData'
import { spaOptions } from '../../../../../../../config/constants'

function HistoryTable({ data }) {
  const [isEditting, setIsEditing] = useState(false)

  //======================== Start - Header templates ========================
  const tHeadTemplate = (
    <ColumnGroup>
      {/* Columns names */}
      <Row>
        {tableHistoryColumns && tableHistoryColumns?.map(col => (
          <Column key={col?.name}
            header={col?.name}
            headerStyle={col?.style}
          />
        ))}
      </Row>
    </ColumnGroup>
  )
  //======================== End - Header templates ========================

  //======================== Start - Row templates ========================
  const countTemplate = (rowData, item) => (
    <>
      {/* <span className="p-column-title">ID</span> */}
      <span style={{ fontWeight: 'bold' }}>
        {item?.rowIndex + 1}
      </span>
    </>
  )
  const checkInTemplate = rowData => (
    <>
      {/* <span className="p-column-title">Date</span> */}
      <div className='ml-2 mr-2'>
        {rowData?.checkinTime
          ? (
            <>
              {parseDateUTCToString(rowData?.checkinTime, 'MM-DD-YYYY')}
              {/* <br /> */}
              . . .
              {parseDateUTCToString(rowData?.checkinTime, 'h:mm A')}
            </>
          )
          : ''}
      </div>
    </>
  )
  const checkOutTemplate = rowData => (
    <>
      {/* <span className="p-column-title">Date</span> */}
      <div className='ml-2 mr-2'>
        {rowData?.checkoutTime
          ? (
            <>
              {parseDateUTCToString(rowData?.checkoutTime, 'MM-DD-YYYY')}
              {/* <br /> */}
              . . .
              {parseDateUTCToString(rowData?.checkoutTime, 'h:mm A')}
            </>
          )
          : ''}
      </div>
    </>
  )
  const spaTemplate = rowData => (
    <>
      {/* <span className="p-column-title">Spa</span> */}
      <span>
        {spaOptions?.find(spa => spa?.value === rowData?.spa)?.name_long}
      </span>
    </>
  )
  const totalBillTemplate = rowData => (
    <>
      {/* <span className="p-column-title">Total Points</span> */}
      <span style={{ fontWeight: 'bold', fontSize: '16px', color: '#007bff' }}>
        {+rowData?.price}
      </span>
    </>
  )
  const pointsTemplate = rowData => (
    <>
      {/* <span className="p-column-title">Current Points</span> */}
      <span style={{ fontWeight: 'bold', fontSize: '16px', color: '#0eb635' }}>
        {+rowData?.reward}
      </span>
    </>
  )
  const redeemTemplate = rowData => (
    <>
      {/* <span className="p-column-title">Current Points</span> */}
      <span style={{ fontWeight: 'bold', fontSize: '16px', color: '#e69318' }}>
        {+rowData?.discount}
      </span>
    </>
  )
  const actionTemplate = rowData => (
    <>
      {isEditting ? (
        <Button id="btnCheckIn" type="button" icon="pi pi-sign-in"
          className="btn-checkin"  // p-button-rounded
          onMouseDown={e => e.preventDefault()}   // prevent open detail dialog when click btnCheckIn
        // onClick={() => handleConfirmCheckIn(rowData?.id)}
        // loading={isLoading.rowId === rowData?.id && isLoading.value}
        // label='Check in'
        />
      ) : (
        <Button id="btnCheckOut" type="button" icon="pi pi-sign-out"
          className="btn-checkout"  // p-button-rounded
        // onClick={() => {
        //   setCustomerInfo(rowData)
        //   toggleDialogs.setOpenDialogCusInfo(true)
        // }}
        />
      )}
    </>
  )
  //======================== End - Row templates ========================

  return (
    <DataTable
      value={data ?? []}
      rowHover stripedRows  //scrollable scrollHeight="200px"
      size="small"
      className="p-datatable-customers-history"
      headerColumnGroup={tHeadTemplate}
      emptyMessage="No history"
    // sortField={filters?.sortBy}
    // sortOrder={filters?.direction === 'DESC' ? -1 : 1}
    // onSort={handleSort}
    >
      <Column body={countTemplate} style={{ textAlign: 'center' }} />
      <Column field="checkinTime" body={checkInTemplate} style={{ textAlign: 'center' }} />
      <Column field="checkoutTime" body={checkOutTemplate} style={{ textAlign: 'center' }} />
      <Column field="spa" body={spaTemplate} style={{ textAlign: 'center' }} />
      <Column field="price" body={totalBillTemplate} style={{ textAlign: 'center' }} />
      <Column field="reward" body={pointsTemplate} style={{ textAlign: 'center' }} />
      <Column field="discount" body={redeemTemplate} style={{ textAlign: 'center' }} />
      {/* <Column body={actionTemplate} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} /> */}
    </DataTable>
  )
}

export default HistoryTable