import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'

import { checkinStatus, getStatusName, updateTypeName, updateTypes } from '../../../../prepareData'
import {
  MyFieldset, Legend, Row, Title, Subtitle, Content,
  InputPoint  // ButtonGroupWrapper, ButtonGroup,
} from './RewardsItems'

function Rewards(props) {
  const { data, inputsValue, setInputsValue } = props
  const { gainingPoints, redeemingPoints, bill } = inputsValue

  // const [isSameGainingPoint, setIsSameGainingPoint] = useState(true)

  // useEffect(() => {
  //   if (isSameGainingPoint) {
  //     setInputsValue({
  //       ...inputsValue, bill: gainingPoints
  //     })
  //     setIsSameGainingPoint(false)
  //   }
  // }, [gainingPoints])

  useEffect(() => {
    // if (isSameGainingPoint) {
    setInputsValue({
      ...inputsValue, gainingPoints: bill
    })
    //   setIsSameGainingPoint(false)
    // }
  }, [bill])

  // const [updateType, setUpdateType] = useState(
  //   data?.isConfirmed ? updateTypeName.REDEEM : updateTypeName.ADD
  // )

  // let listDisabled = []

  // const getDisabledOptions = option => {
  //   if (data?.isConfirmed)
  //     listDisabled.push(updateTypeName.ADD)

  //   if (data?.reward < 300 && !listDisabled?.includes(updateTypeName.REDEEM))
  //     listDisabled.push(updateTypeName.REDEEM)

  //   // Logic chỗ này chưa ổn, tạm thời close lại
  //   // if (disableUpdation && !listDisabled?.includes(updateTypeName.ADD))
  //   //   listDisabled.push(updateTypeName.ADD)

  //   // console.log('getDisabledOptions(): ', listDisabled);

  //   return listDisabled.includes(option)
  // }

  const onChangeInputs = (key, value) => {
    // setIsSameGainingPoint(true)
    setInputsValue({
      ...inputsValue, [key]: value
    })
  }

  return (
    <MyFieldset legend={<Legend>Rewards</Legend>}>
      {/* {data?.status === checkinStatus.CHECK_IN.value && (
        <ButtonGroupWrapper>
          <ButtonGroup value={updateType} options={updateTypes}
            onChange={(e) => {
              if (e.value !== null && e.value !== undefined)
                setUpdateType(e.value)
            }}
            optionDisabled={option => getDisabledOptions(option)}
          // optionDisabled={option => data?.isConfirmed
          //   ? option === updateTypeName.ADD : ''}
          />
        </ButtonGroupWrapper>
      )} */}

      <div className='row'>
        <div className='col-sm-8'>
          <Row>
            <Title>Total visits</Title>
            <Content className='visit-count'>{data?.visitCount || 0}</Content>
          </Row>
        </div>
        <div className='col-sm-4'>
          {data?.status !== checkinStatus.CHECK_OUT.value && (
            <span className={classNames('status-badge',
              'status-' + getStatusName(data?.status, true)
            )}>
              {getStatusName(data?.status)}
            </span>
          )}
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-6'>
          <Row>
            <Title>Lifetime points</Title>
            <Content className='points total'>{data?.totalPoint || 0}</Content>
          </Row>
        </div>
        <div className='col-sm-6'>
          <Row>
            <Title>Current points</Title>
            <Content className='points current'>{(data?.totalPoint - data?.discount) || 0}</Content>
          </Row>
        </div>
      </div>

      {data?.status === checkinStatus.CHECK_IN.value && (
        <>
          <div className='row mb-3'>
            <div className='col-sm-12'>
              <Subtitle>Add or remove from current points </Subtitle>
            </div>

            {/* Gaining Points */}
            <div className='col-sm-6'>
              <div className='d-flex justify-content-start align-items-center ml-1'>
                <Title className='point-change gain'>Gain</Title>

                <InputPoint min={0} locale='en-US'
                  value={gainingPoints}
                  onValueChange={e => onChangeInputs('gainingPoints', e?.value)}
                  onChange={e => onChangeInputs('gainingPoints', e?.value)}
                  prefix='+'
                  // prefix={updateType === updateTypeName.ADD ? '+' : '-'}
                  className='point-change'
                />
              </div>
            </div>

            {/* Redeeming Points */}
            <div className='col-sm-6'>
              <div className='d-flex justify-content-start align-items-center'>
                <Title className='point-change'>Redeem</Title>

                <InputPoint min={0} locale='en-US'
                  value={redeemingPoints}
                  onValueChange={e => onChangeInputs('redeemingPoints', e?.value)}
                  onChange={e => onChangeInputs('redeemingPoints', e?.value)}
                  prefix='-'
                  className='point-change'
                />
              </div>
            </div>
          </div>

          {/* Total Bill */}
          <div className='row mb-3'>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-start align-items-center ml-1'>
                <Title className='bill'>Total Bill</Title>

                <Content>
                  <InputPoint min={0} locale='en-US'
                    mode="currency" currency="USD"
                    value={bill}
                    onValueChange={e => onChangeInputs('bill', e?.value)}
                    onChange={e => onChangeInputs('bill', e?.value)}
                  />
                </Content>
              </div>
            </div>
            <div className='col-sm-6'>

            </div>
          </div>
        </>
      )}
    </MyFieldset>
  )
}

export default Rewards