import styled from 'styled-components';
import { Fieldset } from 'primereact/fieldset';

export const MyFieldset = styled(Fieldset)`
    /* min-height: 60vh; */
    /* padding-bottom: 0.5rem; */
    margin: 0.15rem 0.15rem 0.75rem ;
    /* border: 2px solid #FFC09F; */
    border: none;
    background-color: #ffc19f30;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
`

/*============================== Customer Info ==============================*/
export const CustomerInfoRow = styled.div`
    width: 100%;
    padding: 0 0.25rem;
    display: grid;
    grid-template-columns: 3fr 5fr;
    /* border: 1px solid black; */
`
export const Title = styled.p`
    font-size: 0.9rem;
    font-weight: 600;
`
export const Content = styled.p`
    font-size: 0.9rem;
    /* border: 1px solid black; */
`

/*============================== Booking Info ==============================*/
export const Legend = styled.h6`
    font-weight: 700;
    // Ngoài chỗ này, legend còn đc CSS trong file dialog.css
`
export const Row = styled.div`
    width: 100%;
    padding: 0 0.25rem;
    display: grid;
    grid-template-columns: 2fr 5fr;
    /* border: 1px solid black; */
`

/*============================== Services ==============================*/
export const ServiceFieldSetWrapper = styled.div`
    display: flex;
    flex-direction: column;
    
    /* height: 100px; */

    /* border: 1px solid yellow; */
`
export const ServiceGroupRow = styled.div`
    padding-left: 0.1rem;
    margin: 0.25rem 0;
`
export const ServiceGroupName = styled.h5`
    font-weight: 700;
    font-size: 1.1rem;
`
export const ServiceRow = styled.div`
    padding: 0.25rem 0 0.35rem 1.2rem;
`
export const ServiceName = styled.h6`
    font-weight: 500;
`
export const ServiceOptionRow = styled.div`
    padding: 0.15rem 0 0 1.65rem;
`
export const ServiceOptionTitle = styled.h6`
    color: gray;
    font-size: 0.9rem;
    font-weight: 600;
    font-style: italic;
    display: inline;
`
export const ServiceOptionName = styled.span`
    font-size: 0.925rem;
    font-weight: 500;
`