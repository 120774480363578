import React, { useState, useContext, createContext, useReducer } from 'react'
import { BookingReducer } from './BookingReducer'
import { filterTypes as Filter } from './filter-types'
import { BlankAvatar } from '../../../assets/images'

const BookingContext = createContext()

export function useBooking() {
    return useContext(BookingContext)
}

let defaultParams = {
    direction: 'DESC',
    sortBy: 'time',
    page: 0,
    limit: 10,
    key: '',
    name: '',
    status: null,
    start: null,
    end: null,
    technicianIds: [],
    serviceIds: []
}

function useBookingProvider() {
    const [params, dispatchParams] = useReducer(BookingReducer, defaultParams)
    // Paging
    const [page, setPage] = useState(defaultParams?.page || 0)
    const [limit, setLimit] = useState(defaultParams?.limit || 10)
    // Sorting  // ko cần sort vì Prime tự sort cho mình rồi
    // ==> Muốn sort FE thì phải full data, còn mình dùng phân trang server nên phải sort search và filter từ server hết, ko làm ở FE
    const [direction, setDirection] = useState(params?.direction || 'desc')
    const [sortBy, setSortBy] = useState(params?.sortBy || 'time')
    // Filters
    const [globalSearch, setGlobalSearch] = useState(defaultParams?.key || '')
    const [name, setName] = useState(defaultParams?.name || '')
    // const [dateRange, setDateRange] = useState(defaultParams?.dateRange || [null, null])
    const [start, setStart] = useState(defaultParams?.start || null)
    const [end, setEnd] = useState(defaultParams?.end || null)
    const [technicianIds, setTechnicianIds] = useState(defaultParams?.technicianIds || [])
    const [serviceIds, setServiceIds] = useState(defaultParams?.serviceIds || [])
    const [bookingStatus, setBookingStatus] = useState(defaultParams?.bookingStatus || null)

    const setFilter = (key, value) => {
        switch (key) {
            case Filter.GLOBAL_SEARCH:
                defaultParams = { ...defaultParams, key: value }
                setGlobalSearch(value)
                break;

            case Filter.CUS_NAME:
                defaultParams = { ...defaultParams, name: value }
                setName(value)
                break;

            case Filter.BOOKING_STATUS:
                defaultParams = { ...defaultParams, bookingStatus: value }
                setBookingStatus(value)
                break;

            case Filter.FROM_DATE:
                defaultParams = { ...defaultParams, start: value }
                setStart(value)
                break;

            case Filter.TO_DATE:
                defaultParams = { ...defaultParams, end: value }
                setEnd(value)
                break;

            case Filter.TECHNICIANS:
                defaultParams = { ...defaultParams, technicianIds: value }
                setTechnicianIds(value)
                break;

            case Filter.SERVICES:
                defaultParams = { ...defaultParams, serviceIds: value }
                setServiceIds(value)
                break;

            case Filter.PAGE:
                defaultParams = { ...defaultParams, page: value }
                setPage(value)
                break;

            case Filter.LIMIT:
                defaultParams = { ...defaultParams, limit: value }
                setLimit(value)
                break;

            case Filter.COLUMN:
                defaultParams = { ...defaultParams, sortBy: value }
                setSortBy(value)
                break;

            case Filter.DIRECTION:
                defaultParams = { ...defaultParams, direction: value }
                setDirection(value)
                break;

            default:
                break;
        }
    }

    // Data of the select boxes for filters
    const defaultTechnician = { id: -1, name: 'Anyone', avatar: BlankAvatar }
    const [listTechnicians, setListTechnicians] = useState([defaultTechnician])
    const [listServices, setListServices] = useState([])

    return {
        params, dispatchParams,
        page, setPage,
        limit, setLimit,
        direction, sortBy, //setDirection,
        globalSearch,
        name,
        start, end, // dateRange,
        technicianIds,
        serviceIds,
        bookingStatus,
        setFilter,
        listTechnicians, setListTechnicians,
        listServices, setListServices
    }
}

function BookingProvider({ children }) {
    const booking = useBookingProvider()

    return (
        <BookingContext.Provider value={booking}>
            {children}
        </BookingContext.Provider>
    )
}

export default BookingProvider