import { classNames } from 'primereact/utils'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { checkinStatus, customerType, getCusTypeNameFromId, getStatusName } from '../../prepareData'

const typeItemTemplate = option => {
  return (
    <span className={classNames('cus-type-badge',
      'type-' + getCusTypeNameFromId(option, true))
    }>
      {option}
    </span>
  )
}

const statusItemTemplate = option => {
  return (
    <span className={classNames('status-badge',
      'status-' + getStatusName(option?.value, true))
    }>
      {option?.name}
    </span>
  )
}

const Filters = {
  ID: (value, onChange) => (
    <InputText
      type="search"
      value={value}
      onChange={onChange}
      placeholder="Search ID"
      style={{ width: '100%' }} />
  ),
  Phone: (value, onChange) => (
    // <InputText type="search" value={phone} onChange={(e) => onPhoneChange(e)}
    <InputText
      type="search"
      value={value}
      onChange={onChange}
      placeholder="Search by phone"
      style={{ width: '100%' }} />
  ),
  Name: (value, onChange) => (
    <InputText
      type="search"
      value={value}
      onChange={onChange}
      placeholder="Search by name"
      style={{ width: '100%' }} />
  ),
  Type: (value, onChange) => (
    <Dropdown
      options={Object.values(customerType)}
      value={value}
      onChange={onChange}
      showClear={!!value}
      itemTemplate={typeItemTemplate}
      placeholder="Select a type"
      className="p-column-filter" />
  ),
  Status: (value, onChange) => (
    <Dropdown
      options={Object.values(checkinStatus)}
      value={value}
      onChange={onChange}
      optionLabel="name"
      optionValue="value"
      showClear={!!value}
      itemTemplate={statusItemTemplate}
      placeholder="Select a status"
      className="p-column-filter" />
  ),
}

export default Filters