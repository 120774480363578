export const filterTypes = {
    PAGE: 'PAGE',
    LIMIT: 'LIMIT',
    DIRECTION: 'DIRECTION',
    COLUMN: 'COLUMN',
    GLOBAL_SEARCH: 'GLOBAL_SEARCH',
    CUS_NAME: 'CUS_NAME',
    FROM_DATE: 'FROM_DATE',
    TO_DATE: 'TO_DATE',
    BOOKING_STATUS: 'BOOKING_STATUS',
    SERVICES: 'SERVICES',
    TECHNICIANS: 'TECHNICIANS'
    // ACTIVE_SERVICE: 'ACTIVE_SERVICE',
    // ACTIVE_TECHNICIAN: 'ACTIVE_TECHNICIAN'
}

export const actionTypes = {
    SORT: 'SORT',
    CHANGE_PAGE: 'CHANGE_PAGE',

    //================ Search & Filters ================
    SEARCH: 'SEARCH',
    FILTER_CUS_NAME: 'FILTER_CUS_NAME',
    FILTER_FROM_DATE: 'FILTER_FROM_DATE',
    FILTER_TO_DATE: 'FILTER_TO_DATE',
    FILTER_BOOKING_STATUS: 'FILTER_BOOKING_STATUS',
    FILTER_SERVICES: 'FILTER_SERVICES',
    FILTER_TECHNICIANS: 'FILTER_TECHNICIANS',

    //==================== Services ====================
    FILTER_ACTIVE_SERVICE: 'FILTER_ACTIVE_SERVICE',
    // FILTER_ACTIVE_SERVICE: 'FILTER_ACTIVE_SERVICE',

    //==================== Technicians ====================
    FILTER_ACTIVE_TECHNICIAN: 'FILTER_ACTIVE_TECHNICIAN'
    // FILTER_ACTIVE_TECHNICIAN: 'FILTER_ACTIVE_TECHNICIAN'
}