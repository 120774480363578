import React, { useEffect, useState } from 'react'
// import HistoryTimeline from '../components/HistoryTimeline'
import { parseDateUTCToString } from '../../../../../../utils/DateTime'
import {
  MyFieldset, Legend, Row, Title, Content
} from './HistoryItems'
import { Loading } from '../../../../../../components'
import HistoryTable from './components/HistoryTable'

function History(props) {
  const {
    data, profile, point, history, setHistory, loading, setLoading
  } = props

  useEffect(() => {
    setLoading(false)   //true
    handleReloadHistory(profile?.phone || data?.phone)
  }, [])

  const handleReloadHistory = phoneNo => {
    // getRewardsHistory(phoneNo).then(res => {
    //   setHistory(res?.reverse())
    // })
    //   .catch(err => {
    //     console.log('Error at getRewardsHistory() in DialogCustomerDetail: ', err);
    //   })
    //   .finally(() => setLoading(false))
  }

  return (
    <MyFieldset legend={<Legend>History</Legend>} id="historyFieldset">
      {loading ? <Loading /> : <HistoryTable data={history} />}
      {/* <HistoryTimeline data={history} /> */}
    </MyFieldset>
  )
}

export default History