import React from 'react'
import { ContentContainer } from '../../../components'

function RewardsContent() {
    return (
        <ContentContainer pageName="Rewards" tabName="Rewards Content">
            Coming soon...
        </ContentContainer>
    )
}

export default RewardsContent