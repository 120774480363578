import styled from 'styled-components'
import { Fieldset } from 'primereact/fieldset'

export const MyFieldset = styled(Fieldset)`
    /* min-height: 680px; */
    /* padding-bottom: 0.5rem; */
    margin: 0.15rem;
    /* border: 2px solid #FFC09F; */
    border: none;
    background-color: #ffc19f30;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
`
export const Legend = styled.h6`
    font-weight: 700;
    // Ngoài chỗ này, legend còn đc CSS trong file dialog.css
`

/*============================== Customer Profile ==============================*/
// export const ButtonGroupWrapper = styled.div`
//     width: fit-content;
//     height: 35px;
//     margin: -22px 0 16px auto;
// `
// export const ButtonGroup = styled(SelectButton)`
//     & div.p-button {
//         padding: 4px 12px;
//     }
// `