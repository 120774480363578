import React from 'react'
import { ContentContainer } from '../../components'

function NotFound() {
    return (
        <ContentContainer pageName="Page not found">
            404 - Page not found
        </ContentContainer>
    )
}

export default NotFound
