import styled from 'styled-components'
import { Fieldset } from 'primereact/fieldset'
import { SelectButton } from 'primereact/selectbutton'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'

export const MyFieldset = styled(Fieldset)`
    min-height: 254.325px;
    /* padding-bottom: 0.5rem; */
    margin: 0.15rem;
    /* border: 2px solid #FFC09F; */
    border: none;
    background-color: #ffc19f30;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    
    & input {
      padding: 0 5px 2px;
      border-radius: 3px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
`
export const Legend = styled.h6`
    font-weight: 700;
    // Ngoài chỗ này, legend còn đc CSS trong file dialog.css
`

/*============================== Customer Profile ==============================*/
export const ButtonGroupWrapper = styled.div`
    width: fit-content;
    height: 35px;
    margin: -22px 0 16px auto;
`
export const ButtonGroup = styled(SelectButton)`
    & div.p-button {
        padding: 4px 12px;
    }
`
export const InputPoint = styled(InputNumber)`
    display: block;
    width: 100%;
    max-width: 110px;

    &.point-change {
      max-width: 110px;
    }
    & input {
      width: 100%;
      border-color: #848484;
    }

    /* @media screen and (max-width: 960px) {
      width: 60%;
    }
    @media screen and (max-width: 768px) {
      width: 50%;
    } */
`
export const BtnCheckout = styled(Button)`
    padding: 4px 12px;
`

export const Row = styled.div`
    width: 100%;
    padding: 0 0.25rem;
    margin-bottom: 22px;
    
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
`
export const Title = styled.p`
    min-width: 115px;
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.1;

    &.point-change {
      min-width: unset;
      margin-right: 12px;

      &.gain {
        min-width: 61px;
      }
    }
    &.bill {
      min-width: 73px;
    }
`
export const Subtitle = styled.p`
    width: 100%;
    margin: 0 0 4px 4px;

    font-size: 0.8rem;
    font-weight: 600;
    font-style: italic;
    color: #888888;
`
export const Content = styled.div`
    line-height: 0.9;
    font-size: 0.9rem;

    &.visit-count, &.total, &.current {
      font-size: 1.25rem;
      font-weight: 700;
      color: #007bff;
    }
    &.total {
      color: #e69318;
    }
    &.current {
      color: #0eb635;
    }
`