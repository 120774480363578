import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { default as BlankAvatar } from '../assets/images/blank-ava.jpg'
import './Header.css'

function Header() {
  const history = useHistory()
  const [cookies, setCookie, removeCookie] = useCookies()
  const [openDropdown, setOpenDropdown] = useState(false)

  const handleLogout = () => {
    removeCookie('user', { path: '/' })
    history.replace('/')
  }

  return (
    <div>
      {/* Navbar */}
      <nav className="my-custom-navbar main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="as" role="button">
              <i className="fas fa-bars" />
            </a>
          </li>
        </ul>

        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Expand full screen icon */}
          <li className="nav-item">
            <a className="nav-link" data-widget="fullscreen" href="#" role="button">
              <i className="fas fa-expand-arrows-alt" />
            </a>
          </li>

          {/* Avatar & Name */}
          <div className="user-name-avatar"
            onMouseMove={() => setOpenDropdown(true)}
            onMouseLeave={() => setOpenDropdown(false)}
          >
            <li className="nav-item">
              <div className="user-panel d-flex">
                <div className="image">
                  <img src={BlankAvatar} className="img-circle elevation-1" alt="User Avatar" />
                </div>
                <div className="info"><b>Salon's Manager</b></div>
              </div>

              {openDropdown && (
                <div className="my-dropdown-menu">
                  <button className="dropdown-item" role="button" onClick={handleLogout}>
                    <i className="fas fa-sign-out-alt mr-2" /> Log out
                  </button>
                  <div className="dropdown-divider" />
                  {/* <button className="dropdown-item" role="button">
                      <i className="fas fa-user mr-2" /> Profile
                  </button> */}
                </div>
              )}
            </li>
          </div>

          {/* A Gia's */}
          {/* <li className="nav-item dropdown mr-auto">
                        <a className="image nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="../../dist/img/user2-160x160.jpg" className="img-circle elevation-1" alt="User Avatar" />
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a className="dropdown-item" href="/course/create">Đăng khóa học</a>
                            <a className="dropdown-item" href="/me/stored-course">Khóa học của tui</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">Something else here</a>
                        </div>
                    </li> */}

        </ul>
        {/* Sidebar user (optional) */}
        {/* <div className="user-panel d-flex">
                    <div className="image">
                        <img src="../../dist/img/user2-160x160.jpg" className="img-circle elevation-1" alt="User Avatar" />
                    </div>
                    <div className="info">
                        <b>Salon's Manager</b>
                    </div>
                </div> */}
      </nav>
    </div>

  )
}

export default Header
