import React, { useState, useContext, createContext, useEffect } from 'react'
import { getNumberOfAppointmentByStatus } from '../services/AppContextApi'  // , refreshToken
import authenApi from '../services/AuthenticationApi'
import { bookingStatuses } from '../utils/constants'
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

const AppContext = createContext()

export function useApp() {
    return useContext(AppContext)
}

function useAppProvider() {
    const [countPendingBooking, setCountPendingBooking] = useState(0)
    const [countPendingRewards, setCountPendingRewards] = useState(0)

    const [cookies, setCookie, removeCookie] = useCookies()

    const [openNetworkModal, setOpenNetworkModal] = useState({type: '', isOpen: false})
    // const [retryFunction, setRetryFunction] = useState(() => {})

    const history = useHistory()

    const getCountPendingBooking = (status) => {
        getNumberOfAppointmentByStatus(status).then(res => {
            setCountPendingBooking(res)
        })
        .catch(error => {
            if (error?.message?.includes('Network Error')) {
                setTimeout(() => {
                    setOpenNetworkModal({type: 'Internet', isOpen: true})
                }, 3000);
            }
            else if (error?.response?.status === 403 && error?.response?.data?.error_message?.includes('The Token has expired')) {
                const functionRetry = () => getCountPendingBooking(status)
                request4RefreshToken(functionRetry)
            } else if (error?.response?.status === 403 && error?.response?.data?.error === 'Forbidden') {
                history.replace('/')
                // alert('You are not allowed to use this feature. Please login again.')
            } else {
                console.log('Error at AppContext -- getCountPendingBooking(): ', error);
            }
        })
    }
    useEffect(() => {
        getCountPendingBooking(bookingStatuses.pending)
    }, [])

    const getCountPendingRewards = (status) => {
        getNumberOfAppointmentByStatus(status).then(res => {
            setCountPendingRewards(res)
        })
        .catch(error => {
            if (error?.message?.includes('Network Error')) {
                setTimeout(() => {
                    setOpenNetworkModal({type: 'Internet', isOpen: true})
                }, 3000);
            }
            else if (error?.response?.status === 403 && error?.response?.data?.error_message?.includes('The Token has expired')) {
                const functionRetry = () => getCountPendingRewards(status)
                request4RefreshToken(functionRetry)
            } else if (error?.response?.status === 403 && error?.response?.data?.error === 'Forbidden') {
                history.replace('/')
                // alert('You are not allowed to use this feature. Please login again.')
            } else {
                console.log('Error at AppContext -- getCountPendingRewards(): ', error);
            }
        })
    }
    useEffect(() => {
        getCountPendingRewards(bookingStatuses.pending)
    }, [])

    const refreshAccessToken = () => {
        return new Promise(resolve => {
            setTimeout(() => {
                authenApi.refreshToken().then(res => {
                    const user = {
                        access_token: res?.accessToken,
                        refresh_token: res?.refreshToken,
                    };
                    removeCookie('user', { path: '/' })
                    setCookie('user', user, { path: '/' })

                    resolve(true)
                })
                    .catch(error => {
                        console.log('Error at refreshAccessToken(): ', error);
                        removeCookie('user', { path: '/' })
                        history.replace('/')
                    })
            }, 2000)
        })
    }

    let refreshTokenRequest = null
    const request4RefreshToken = async (functionRetry) => {
        refreshTokenRequest = refreshTokenRequest ? refreshTokenRequest : refreshAccessToken()
        const didRefresh = await refreshTokenRequest
        refreshTokenRequest = null

        if (didRefresh === true) {
            functionRetry()
        }
        return;
    }

    return { 
      countPendingBooking,
      getCountPendingBooking,
      countPendingRewards,
      getCountPendingRewards,
      request4RefreshToken,
      openNetworkModal, setOpenNetworkModal,
      // retryFunction, setRetryFunction
    }
}

function AppProvider({ children }) {
    const app = useAppProvider()
    return (
        <AppContext.Provider value={app}>
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider