import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useBooking } from './hooks/BookingContext'
import { useApp } from '../../hooks/AppContext'
import { getAppointments } from '../../services/BookingApi'
import StyledTable from './components/StyledTable'
import { Loading, ContentContainer } from '../../components'

function Booking() {
    const history = useHistory()
    const { params } = useBooking()
    const { request4RefreshToken, setOpenNetworkModal } = useApp()  // setRetryFunction
    const [data, setData] = useState(null)

    const [loading, setLoading] = useState(false)

    const getListAppointments = (params) => {
          getAppointments(params).then(res => {
          setData(res)
          setLoading(false)
        })
        .catch(error => {
            if (error?.message?.includes('Network Error')) {
                setTimeout(() => {
                    setLoading(false)
                    setOpenNetworkModal({type: 'Internet', isOpen: true})
                    // setRetryFunction(() => getListAppointments(params))
                }, 3000);
            }
            else if (error?.response?.status === 403 && error?.response?.data?.error_message?.includes('The Token has expired')) {
                const functionRetry = () => getListAppointments(params)
                request4RefreshToken(functionRetry)
            }
            else if (error?.response?.status === 403 && error?.response?.data?.error === 'Forbidden') {
                setLoading(false)
                history.replace('/')
                // alert('You are not allowed to use this feature. Please login again.')
            }
            else {
                setLoading(false)
                console.log('Error at getListAppointments: ', error)
                // history.push({
                //     pathname: '/errors',
                //     state: { error: error.response.status },
                // })
            }
        })
    }

    useEffect(() => {
        setLoading(true)
    }, [])

    useEffect(() => {
        getListAppointments(params)
    }, [params])

    return (
        <ContentContainer pageName="Appointments">
          {loading ? <Loading /> : (
            <StyledTable 
                data={data?.list}
                refreshData={getListAppointments}
                totalElements={data?.totalElements}
                totalPage={data?.totalPage} 
                setLoading={setLoading}
            />
          )}
        </ContentContainer>
    )
}

export default Booking