import React, { useRef, useState } from 'react'
import SockJsClient from 'react-stomp'
import { Toast } from 'primereact/toast'

import ENDPOINTS from '../../config/endpoints'
import { useCustomer } from './hooks/CustomerContext'
import { ContentContainer, Loading } from '../../components'
import { CustomersTable } from './components'
import { DialogCustomerDetail } from './dialogs'

function CheckinCheckout() {
  const { isLoading, getListCustomers, filters } = useCustomer()

  const [customerInfo, setCustomerInfo] = useState(null)

  const [openDialogCusInfo, setOpenDialogCusInfo] = useState(false);
  // const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const toggleDialogs = {
    openDialogCusInfo, setOpenDialogCusInfo
    // openDetailDialog, setOpenDetailDialog, setDialogType
  }

  const toast = useRef(null)

  const toastMsg = {
    showSuccessToast: message => {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: message,  // 'Updated rewards successfully'
        life: 3000
      })
    },
    showErrorToast: message => {
      toast.current.show({
        severity: 'error',
        summary: 'Failure',
        detail: message,  // 'Updated rewards failed'
        life: 3000
      })
    }
  }

  const handleSocketMessage = () => {
    getListCustomers(filters)
  }

  return (
    <ContentContainer pageName="Check-in / Check-out">
      <SockJsClient
        url={ENDPOINTS.SOCKET_URL}
        topics={['/topic/message']}
        onMessage={handleSocketMessage}
      />

      {isLoading ? <Loading /> : (
        <CustomersTable
          setCustomerInfo={setCustomerInfo}
          toggleDialogs={toggleDialogs}
          toastMsg={toastMsg}
        // data={data?.list}
        // totalRecords={data?.totalElements}
        // totalPages={data?.totalPage}
        />
      )}

      {/* Dialogs */}
      {openDialogCusInfo && (
        <DialogCustomerDetail
          open={openDialogCusInfo}
          setOpen={setOpenDialogCusInfo}
          data={customerInfo}
          toastMsg={toastMsg}
        />
      )}

      <Toast ref={toast} />
    </ContentContainer>
  )
}

export default CheckinCheckout