import React, { useState } from 'react'
import { ListBox } from 'primereact/listbox'
import { listDays } from '../../data'
import TimePicker from './TimePicker'
import './DefaultOption.css'

function DefaultOption({ option, toast }) {
    const [selectedDay, setSelectedDay] = useState(listDays[0]?.value)
    // console.log('listDays = ', listDays);

    const getSelectedDayName = (dayValue) => {
        if (dayValue === 0)
            return listDays[0]?.label
        else if (dayValue === 1)
            return 'Sunday'
        return listDays[selectedDay - 1]?.label
    }

    return (
        <div className="default-option">
            <div className="default-option__list-days">
                <ListBox value={selectedDay} options={listDays} optionLabel="label"
                    onChange={(e) => {
                        if (e.value !== null && e.value !== undefined)
                            setSelectedDay(e.value)
                        // console.log('setSelectedDay: ', e.value);
                    }} />
            </div>
            <TimePicker option={option} toast={toast} day={getSelectedDayName(selectedDay)} dayId={selectedDay} />
            {/* <TimePicker option={option} day={getSelectedDayName(selectedDay)} setDay={setSelectedDay} /> */}
        </div>
    )
}

export default DefaultOption
