import React, { useRef, useState } from 'react'
import Highlighter from "react-highlight-words"

import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Row } from 'primereact/row'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { classNames } from 'primereact/utils'
import { Dropdown } from 'primereact/dropdown'
import { Ripple } from 'primereact/ripple'
import { ScrollTop } from 'primereact/scrolltop'

import { parseDateUTCToString } from '../../../../utils/DateTime'
import { checkinStatus, filterTypes, getCusTypeNameFromId, getStatusName, tableColumns } from '../../prepareData'
import { useCustomer } from '../../hooks/CustomerContext'
// import useCustomerAppContext from '../../hooks/useCustomerAppContext'
import { Filters, Header } from '..'
import './CustomersTable.scss'
import { confirmCheckIn } from '../../../../services/CustomersApi'

function CustomersTable(props) {
  const { setCustomerInfo, toggleDialogs, toastMsg } = props   // data, totalRecords, totalPages

  const {
    data: customerData,
    filters, updateFilters,
    getListCustomers
  } = useCustomer()
  // const { data, totalRecords, totalPages } = customerData

  const [isLoading, setIsLoading] = useState({ rowId: -1, value: false })

  const tableRef = useRef(null)

  //============= Start - Actions handlers =============
  const handleConfirmCheckIn = customerId => {
    setIsLoading({ rowId: customerId, value: true })

    confirmCheckIn(customerId).then(res => {
      getListCustomers(filterTypes)
      toastMsg?.showSuccessToast('Checkin successfully')
    })
      .finally(() => {
        setIsLoading({ rowId: customerId, value: false })
      })
  }
  //============= End - Actions handlers =============

  //============= Start - Filters handlers =============
  const handleSort = (sortValue) => {
    updateFilters(filterTypes.SORT_BY, sortValue?.sortField)
    updateFilters(filterTypes.DIRECTION, sortValue?.sortOrder === -1 ? 'DESC' : 'ASC')
    // getCountPendingBooking(listBookingStatuses.pending)
  }

  const onIdChange = e => {
    // updateFilters(filterTypes.PAGE, 0)
    updateFilters(filterTypes.ID, e?.target?.value)
  }
  const onPhoneChange = e => {
    // updateFilters(filterTypes.PAGE, 0)
    updateFilters(filterTypes.PHONE, e?.target?.value)
  }
  const onCusNameChange = e => {
    // updateFilters(filterTypes.PAGE, 0)
    updateFilters(filterTypes.NAME, e?.target?.value)
  }
  const onTypeChange = e => {
    updateFilters(filterTypes.TYPE, e?.value)
  }
  const onStatusChange = e => {
    updateFilters(filterTypes.STATUS, e?.value)
  }
  //============= End - Filters handlers =============

  //======================== Start - Header templates ========================
  const tHeadTemplate = (
    <ColumnGroup>
      {/* Columns names */}
      <Row>
        {tableColumns && tableColumns?.map(col => (
          <Column key={col?.name}
            header={col?.name}
            headerStyle={col?.style}
            rowSpan={col?.rowSpan}
            sortable={col?.sortable}
            sortField={col?.fieldName}
          />
        ))}
      </Row>

      {/* Filters */}
      <Row>
        <Column filter
          filterField="id"
          filterElement={Filters?.ID(filters?.id, onIdChange)} />
        <Column filter
          filterField="fullName"
          filterElement={Filters?.Name(filters?.name, onCusNameChange)} />
        <Column filter
          filterField="phone"
          filterElement={Filters?.Phone(filters?.phone, onPhoneChange)} />
        <Column filter
          filterField="type"
          filterElement={Filters?.Type(filters?.type, onTypeChange)} />
        <Column filter
          filterField="status"
          filterElement={Filters?.Status(filters?.status, onStatusChange)} />
      </Row>
    </ColumnGroup>
  )
  //======================== End - Header templates ========================

  //======================== Start - Row templates ========================
  const idBodyTemplate = (rowData, index) => (
    <>
      <span className="p-column-title">ID</span>
      <span style={!rowData?.isConfirmed ? { fontWeight: 'bold' } : {}}>
        {rowData?.id}
      </span>
    </>
  )
  const nameBodyTemplate = rowData => (
    <div className='d-flex justify-content-between'>
      <span className="p-column-title">Customer Name</span>
      <div>
        <div className="cus-name mt-1 mb-1 ml-2 mr-2">
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[filters?.name]}
            autoEscape={true}
            textToHighlight={rowData?.fullName || ''}
            highlightStyle={{ backgroundColor: 'rgba(255, 192, 159, 0.75)' }}
          />
        </div>
        {rowData?.email && (
          <div className="cus-email mt-1 mb-1 ml-2 mr-2">
            <div className="cusEmail">
              <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[filters?.name]}
                autoEscape={true}
                textToHighlight={rowData?.email || ''}
                highlightStyle={{ backgroundColor: 'yellow' }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
  const phoneBodyTemplate = rowData => (
    <div className='d-flex justify-content-between'>
      <span className="p-column-title">Customer Phone</span>
      <div className="cus-phone ml-2 mr-2">
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={[filters?.phone]}
          autoEscape={true}
          textToHighlight={rowData?.phone || ''}
          highlightStyle={{ backgroundColor: 'yellow' }}
        />
      </div>
    </div>
  )
  const cusTypeBodyTemplate = rowData => (
    <>
      <span className="p-column-title">Customer Type</span>
      <span className={classNames('cus-type-badge',
        'type-' + getCusTypeNameFromId(rowData?.type, true)
      )}>
        {getCusTypeNameFromId(rowData?.type)}
      </span>
    </>
  )
  const visitCntBodyTemplate = rowData => (
    <>
      <span className="p-column-title">Visit counts</span>
      <span>{rowData?.visitCount}</span>
    </>
  )
  const lastVisitedBodyTemplate = rowData => (
    <>
      <span className="p-column-title">Date</span>
      <div className='ml-2 mr-2'>
        {rowData?.lastVisited
          ? (
            <>
              {parseDateUTCToString(rowData?.lastVisited, 'MMM DD, YYYY')}
              {/* <br /> */}
              . . .
              {parseDateUTCToString(rowData?.lastVisited, 'h:mm A')}
            </>
          )
          : ''}
      </div>
    </>
  )
  const totalPointsBodyTemplate = rowData => (
    <>
      <span className="p-column-title">Total Points</span>
      <span style={{ fontWeight: 'bold' }}>
        {+rowData?.totalPoint}
      </span>
    </>
  )
  const pointBodyTemplate = rowData => (
    <>
      <span className="p-column-title">Current Points</span>
      <span style={{ fontWeight: 'bold' }}>
        {+rowData?.totalPoint - +rowData?.discount}
      </span>
    </>
  )
  const statusBodyTemplate = rowData => (
    <>
      <span className="p-column-title">Status</span>

      {rowData?.status !== checkinStatus.CHECK_OUT.value && (
        <span
          className={classNames('status-badge',
            'status-' + getStatusName(rowData?.status, true)
          )}
        >
          {getStatusName(rowData?.status)}
        </span>
      )}
    </>
  )
  const actionBodyTemplate = rowData => (
    <>
      {rowData?.status === checkinStatus.REQUEST?.value ? (
        <Button id="btnCheckIn" type="button" icon="pi pi-sign-in"
          className="btn-checkin"  // p-button-rounded
          onMouseDown={e => {
            // prevent open detail dialog when click btnCheckIn
            e.preventDefault()
            toggleDialogs.setOpenDialogCusInfo(false)
          }}
          onClick={() => handleConfirmCheckIn(rowData?.id)}
          loading={isLoading.rowId === rowData?.id && isLoading.value}
        // label='Check in'
        />
      )
        : rowData?.status === checkinStatus.CHECK_IN?.value ? (
          <Button id="btnCheckOut" type="button" icon="pi pi-sign-out"
            className="btn-checkout"  // p-button-rounded
            onClick={() => {
              setCustomerInfo(rowData)
              toggleDialogs.setOpenDialogCusInfo(true)
            }}
          // style={{ fontWeight: 700 }}
          // label='Check out'
          />
        ) : (
          <></>
        )
      }
    </>
  )
  //======================== End - Row templates ========================

  //======================== Start - Paginator ========================
  const handleChangePage = event => {
    if (event === filters?.page || filters?.page === event?.target?.innerText - 1)
      return

    let newPage = event
    if (event?.target?.innerText)
      newPage = parseInt(event?.target.innerText) - 1

    updateFilters(filterTypes.PAGE, newPage)
  }

  const handleChangeLimit = (event) => {
    // updateFilters(filterTypes.PAGE, 0)
    updateFilters(filterTypes.LIMIT, +event?.target?.value)

    // setFilter(filterTypes.LIMIT, parseInt(event?.target?.value, 15));   // hoặc "event?.value" cũng đc
    // setFilter(filterTypes.PAGE, 0);
    // // setLimit(event?.target?.value)
    // // setPage(0)
  }

  const paginatorTemplate = {
    layout: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
    FirstPageLink: (options) => {
      return (
        <Button
          type="button"
          className={options.className}
          onClick={e => {
            options.onClick(e)
            handleChangePage(0)
          }}
          disabled={options.disabled}
        >
          <i className="pi pi-angle-double-left" />
          <Ripple />
        </Button>
      );
    },
    PrevPageLink: (options) => {
      return (
        <Button
          type="button"
          className={options.className}
          onClick={e => {
            options.onClick(e)
            handleChangePage(filters?.page - 1)
          }}
          disabled={options.disabled}
        >
          <i className="pi pi-angle-left" />
          <Ripple />
        </Button>
      );
    },
    NextPageLink: (options) => {
      return (
        <Button
          type="button"
          className={options.className}
          onClick={e => {
            options.onClick(e)
            handleChangePage(filters?.page + 1)
          }}
          disabled={options.disabled}
        >
          <i className="pi pi-angle-right" />
          <Ripple />
        </Button>
      );
    },
    LastPageLink: (options) => {
      return (
        <Button
          type="button"
          className={options.className}
          onClick={e => {
            options.onClick(e)
            handleChangePage(customerData?.totalPages - 1)
          }}
          disabled={options.disabled}
        >
          <i className="pi pi-angle-double-right" />
          <Ripple />
        </Button>
      );
    },
    PageLinks: (options) => {
      if (
        (options?.view?.startPage === options?.page &&
          options?.view?.startPage !== 0) ||
        (options?.view?.endPage === options?.page &&
          options?.page + 1 !== options?.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span className={className} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }

      return (
        <Button
          type="button"
          className={options.className}
          // onClick={options.onClick}
          // onClick={handleChangePage}
          onClick={e => {
            options.onClick(e)
            handleChangePage(e)
          }}
        >
          {options.page + 1}
          <Ripple />
        </Button>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 15, value: 15 },
        { label: 30, value: 30 },
        { label: 50, value: 50 }
      ];
      return (
        <Dropdown
          value={filters?.limit}
          // value={options.value}
          options={dropdownOptions}
          // onChange={options.onChange}
          appendTo={document.body}
          onChange={handleChangeLimit}
        />
      );
    }
  }
  //======================== End - Paginator templates ========================

  return (
    <div className='table-container'>
      {/* <Filters /> */}
      {/* <Toast ref={toast} /> */}

      <div className='customers-table'>
        <DataTable
          ref={tableRef} dataKey="phone"
          value={customerData?.list ?? []}
          rowHover stripedRows  //scrollable scrollHeight="200px"
          size="small"
          className="p-datatable-sm p-datatable-customers"
          header={<Header />}
          headerColumnGroup={tHeadTemplate}
          onRowDoubleClick={e => {
            setCustomerInfo(e?.data)
            toggleDialogs.setOpenDialogCusInfo(true)
          }}
          emptyMessage="No customers found"
          paginator paginatorTemplate={paginatorTemplate}
          lazy first={0} rows={filters?.limit}
          currentPageReportTemplate="Showing {first}-{last} of {totalRecords} entries"
          totalRecords={customerData?.totalElements ?? 0}
          responsiveLayout="stack"
          breakpoint="960px"
          sortField={filters?.sortBy}
          sortOrder={filters?.direction === 'DESC' ? -1 : 1}
          onSort={handleSort}
        >
          <Column field='id' body={idBodyTemplate} style={{ textAlign: 'center' }} />
          <Column field='fullName' body={nameBodyTemplate} />
          <Column field='phone' body={phoneBodyTemplate} />
          <Column body={cusTypeBodyTemplate} style={{ textAlign: 'center' }} />
          <Column body={visitCntBodyTemplate} style={{ textAlign: 'center' }} />
          <Column field="lastVisited" body={lastVisitedBodyTemplate} style={{ textAlign: 'center' }} />
          <Column field="lifetimePoints" body={totalPointsBodyTemplate} style={{ textAlign: 'center' }} />
          <Column field="currentPoints" body={pointBodyTemplate} style={{ textAlign: 'center' }} />
          <Column field="status" body={statusBodyTemplate} style={{ textAlign: 'center' }} />
          <Column body={actionBodyTemplate} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
        </DataTable>
      </div>

      <ScrollTop />
    </div>
  )
}

export default CustomersTable