export const customerType = {
  DIAMOND: 'DIAMONDS VIP',
  VIP: 'VIP',
  REGULAR: 'REGULAR',
  // NORMAL: 'NORMAL',
  // AT_RISK: 'AT RISK',
  NEW: 'NEW'
}

export const checkinStatus = {
  REQUEST: { value: 'REQUEST', name: 'waiting' },
  CHECK_IN: { value: 'CHECK-IN', name: 'in service' },
  CHECK_OUT: { value: 'CHECK-OUT', name: 'check out' },
}

export const filterTypes = {
  DIRECTION: 'direction',
  SORT_BY: 'sortBy',
  PAGE: 'page',
  LIMIT: 'limit',
  SPA: 'spa',
  ID: 'id',
  NAME: 'name',
  PHONE: 'phone',
  TYPE: 'type',
  STATUS: 'status',
}

export const tableColumns = [
  {
    name: 'ID',
    fieldName: 'id',
    style: {
      width: '8%', textAlign: 'center'
    },
    rowSpan: 1,
    sortable: true
  },
  {
    name: 'Customer Name',
    fieldName: 'fullName',
    style: {
      width: '18%', textAlign: 'center'
    },
    rowSpan: 1,
    sortable: false
  },
  {
    name: 'Customer Phone',
    fieldName: 'phone',
    style: {
      width: '12%', textAlign: 'center'
    },
    rowSpan: 1,
    sortable: false
  },
  {
    name: 'Type',
    fieldName: 'type',
    style: {
      width: '10%', textAlign: 'center'
    },
    rowSpan: 1,
    sortable: false
  },
  {
    name: 'Visit Count',
    fieldName: 'visited_count',
    style: {
      width: '8%', textAlign: 'center'
    },
    rowSpan: 2,
    sortable: true
  },
  {
    name: 'Last visited',
    fieldName: 'last_visited',
    style: {
      width: '18%', textAlign: 'center'
    },
    rowSpan: 2,   // Nếu sau này a cho search thì rowSpan=1
    sortable: true
  },
  {
    name: 'Lifetime Points',
    fieldName: 'total_point',
    style: {
      width: '10%', textAlign: 'center'
    },
    rowSpan: 2,
    sortable: true
  },
  {
    name: 'Current Points',
    style: {
      width: '10%', textAlign: 'center'
    },
    rowSpan: 2,
    sortable: false
  },
  {
    name: 'Status',
    fieldName: 'status',
    style: {
      width: '12%', textAlign: 'center'
    },
    rowSpan: 1,
    sortable: true
  },
  {
    name: '',
    style: {
      width: '7%', textAlign: 'center'
    },
    rowSpan: 2,
    sortable: false
  },
]
export const tableHistoryColumns = [
  {
    name: '#',
    style: {
      width: '6%', textAlign: 'center'
    }
  },
  {
    name: 'Checked in at',
    style: {
      width: '20%', textAlign: 'center'
      // width: '20%', textAlign: 'center'
    }
  },
  {
    name: 'Checked out at',
    style: {
      width: '20%', textAlign: 'center'
    }
  },
  {
    name: 'Spa name',
    style: {
      width: '20%', textAlign: 'center'
    }
  },
  {
    name: 'Total Bill',
    style: {
      width: '13%', textAlign: 'center'
    }
  },
  {
    name: 'Points',
    style: {
      width: '13%', textAlign: 'center'
    }
  },
  {
    name: 'Redeem',
    style: {
      width: '13%', textAlign: 'center'
    }
  },
  // {
  //   name: '',
  //   style: {
  //     width: '10%', textAlign: 'center'
  //   }
  // },
]

export const getCusTypeNameFromId = (cusTypeId, isCSS = false) => {
  switch (cusTypeId) {
    case customerType.DIAMOND:
      return isCSS ? 'diamond' : 'diamonds vip'

    case customerType.VIP:
      return 'vip'

    case customerType.REGULAR:
      return 'regular'

    // case customerType.NORMAL:
    //   return 'normal'

    // case customerType.AT_RISK:
    //   return isCSS ? 'at-risk' : 'at risk'

    case customerType.NEW:
      return 'new'

    default:
      return cusTypeId
  }
}

export const getStatusName = (statusAPI, isCSS = false) => {
  switch (statusAPI) {
    case checkinStatus.REQUEST.value:
      return checkinStatus.REQUEST.name

    case checkinStatus.CHECK_IN.value:
      return isCSS
        ? checkinStatus.CHECK_IN.name?.replace(' ', '-')
        : checkinStatus.CHECK_IN.name

    case checkinStatus.CHECK_OUT.value:
      return isCSS
        ? checkinStatus.CHECK_OUT.name?.replace(' ', '-')
        : checkinStatus.CHECK_OUT.name

    default:
      return ''
  }
}