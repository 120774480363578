import axios from 'axios';
import { Cookies } from 'react-cookie'
import ENDPOINT from '../config/endpoints'
import jwt_decode from 'jwt-decode'

const _getToken = () => {
    const cookies = new Cookies()
    const accessToken = cookies.get('user')?.access_token
    let decodedToken = null
    let expTime = 0

    if (accessToken) {
        // decodedToken = jwt_decode(String(accessToken).trim());
        decodedToken = jwt_decode(accessToken);
        expTime = decodedToken?.exp
    }

    return { accessToken, expTime }
}

const axiosClient = axios.create({
    baseURL: ENDPOINT.BASE_URL,
    // 'https://api.deluxenailsspacoppell.com/deluxenails-be-0.0.1-SNAPSHOT',
    // 'http://160.251.76.80:8080/deluxenails-be-0.0.1-SNAPSHOT/',
    timeout: 5000,
    headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
})

axiosClient.interceptors.request.use(async (config) => {
    // Handle token here (if any)
    const { accessToken } = _getToken()     //, expTime
    // console.log('config = ', config, config.baseURL, config.url, config.headers, config.auth);

    if (accessToken) {
        // if (expTime < new Date().getTime()) {   // token expired
        //     console.log(new Date(expTime), ', ', new Date());
        //     console.log(expTime, ', ', new Date().getTime());
        //     // refreshToken() here
        // } else {
        config.headers['Authorization'] = `Bearer ${accessToken}`
        // }
    }

    return config;
});

axiosClient.interceptors.response.use((response) => {
    if (response && ![null, undefined].includes(response?.data)) {
        return response?.data
    }
    return response;
}, (error) => {
    console.log('Error at axiosClient.interceptors.response: ', error);
    // Handle errors
    // if (error && error?.response?.status) {
    // if (error?.response?.status === 401 && error.response.data?.error === '') {
    // }
    // }
    throw error;
});

export default axiosClient;