import { parseDateToString } from "../../utils/DateTime"

// Select button options
export const options = ['Custom', 'Default']
export const optionName = {
    default: 'Default',
    custom: 'Custom'
}

// List days of week in option Default
export const listDays = [
    { label: 'Default time frame', value: 0 },
    { label: 'Monday', value: 2 },
    { label: 'Tuesday', value: 3 },
    { label: 'Wednesday', value: 4 },
    { label: 'Thursday', value: 5 },
    { label: 'Friday', value: 6 },
    { label: 'Saturday', value: 7 },
    { label: 'Sunday', value: 1 },
]

// Content of note in time picker component (right side)
export const noteContent = (option, day) => {
    switch (option) {
        case optionName.default:
            if (day === listDays[0]?.label) {
                return <p className="note__content"> This time frame has not been applied for any day/date yet. <br />
                    If you want to apply this for a specific day in week, please click on button "Apply default time frame".
                </p>
            }

            return <p className="note__content"> This action is applied for <strong>all {day}</strong>. <br />
                If you want to edit for a specific date, please choose option “<b>Custom</b>”.
            </p>
        case optionName.custom:
            return <p className="note__content"> This action is only applied for
                <span className="note__selected-date">
                    {parseDateToString(day, 'MMM DD, YYYY')}
                </span><br />
                If you want to edit the default booking time, please choose option “<b>Default</b>”.
            </p>

        default:
            break;
    }
}