import { 
  Dashboards,
  Booking, BookingProvider,
  RewardsHistory, RewardsProvider,
  BookingTime,
  Services,
  // Technicians,
  Gallery,
  SalonProfile,
  CustomerMessages,
  RewardsContent,
  CheckinCheckout,
  CustomerProvider
} from '../pages'

export const routerData = [
    // {
    //     path: '/login',
    //     name: 'Login',
    //     component: () => { }
    // },
    {
        path: '/dashboards',
        name: 'Dashboards',
        component: () => <Dashboards />
    },
    {
        path: '/checkin-checkout',
        name: 'Check-in / Check-out',
        component: () => (
            <CustomerProvider>
                <CheckinCheckout />
            </CustomerProvider>
        )
    },
    {
        path: '/appointments',
        name: 'Appointments',
        component: () => (
            <BookingProvider>
                <Booking />
            </BookingProvider>
        )
    },
    // {
    //   path: '/rewards-history',
    //   name: 'Rewards History',
    //   component: () => (
    //     <RewardsProvider>
    //       <RewardsHistory />
    //     </RewardsProvider>
    //   )
    // },
    {
        path: '/rewards-content',
        name: 'Rewards Content',
        component: () => (
          <RewardsContent />
        ),
        // children: [
        //   {
        //       path: '/rewards/content',
        //       name: 'Rewards Content',
        //       component: () => (
        //           <p>Rewards Content</p>
        //       )
        //   },
        //   {
        //       path: '/rewards',
        //       name: 'Rewards History',
        //       component: () => (
        //           <RewardsProvider>
        //             <RewardsHistory />
        //           </RewardsProvider>
        //       )
        //   },
        // ]
    },
    {
        path: '/services',
        name: 'Services',
        component: () => <Services />
    },
    // {
    //     path: '/technicians',
    //     name: 'Technicians',
    //     component: () => <Technicians />
    // },
    {
        path: '/gallery',
        name: 'Gallery',
        component: () => <Gallery />
    },
    {
        path: '/bookingTime',
        name: 'Booking Time',
        component: () => <BookingTime />
    },
    {
        path: '/salonProfile',
        name: "Salon's Profile",
        component: () => <SalonProfile />
    },
    {
        path: '/messages',
        name: "Customer's Messages",
        component: () => <CustomerMessages />
    },
]

// const [login, dashboard, appointments, services, technicians, gallery, bookingTime, salonProfile] = routerData
