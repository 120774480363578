import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import authenApi from '../../services/AuthenticationApi'
import { useCookies } from 'react-cookie';
import './LoginForm.css';

function LoginForm() {
    const history = useHistory()
    const [cookies, setCookie] = useCookies(['user']);

    const [errorMsg, setErrorMsg] = useState('')
    const [formData, setFormData] = useState({});
    const defaultValues = {
        username: '',
        password: '',
        remember: false
    }
    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    useEffect(() => {
        const accessToken = cookies?.user?.access_token;
        // console.log('Login form --- accessToken = ', accessToken);

        if (accessToken) {
            history.replace('/dashboards')
        }
    }, [])

    const onSubmit = (data) => {
        setFormData(data);
        const userData = {
            username: data?.username,
            password: data?.password
        }

        authenApi.login(userData).then(res => {
            // console.log('userData = ', userData);
            // console.log('res = ', res);
            const user = {
                access_token: res?.accessToken,
                refresh_token: res?.refreshToken,
                // roles: data.roles,
                // username: data.username,
            };
            // localStorage.setItem('user', JSON.stringify(user))
            // console.log('user: ', user);
            setCookie('user', user, { path: '/' })

            setErrorMsg('')
            history.replace('/dashboards')
        }).catch(error => {
            console.log('Error at login: ', error);
            // console.log(error.response);
            if (error?.response?.status === 401) {
                setErrorMsg('Invalid username or password!')
            }
        })

        reset();
    };
    // console.log('errorMsg = ', errorMsg);

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    // console.log(cookies.user.refresh_token, "cookie")

    return (
        <div className="form-demo">
            <div className="p-d-flex p-jc-center">
                <div className="card">
                    <h3 className="p-text-center">Login</h3>

                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                        <div className="p-field">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-user" />
                                <Controller name="username" control={control} rules={{ required: 'Username is required.' }} render={({ field, fieldState }) => (
                                    <InputText id={field.username} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                <label htmlFor="username" className={classNames({ 'p-error': errors.username })}>Username *</label>
                            </span>
                            {getFormErrorMessage('username')}
                        </div>
                        <div className="p-field">
                            <span className="p-float-label">
                                <Controller name="password" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                    <Password id={field.name} feedback={false} autoComplete="off" {...field} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>Password *</label>
                            </span>
                            {getFormErrorMessage('password')}
                        </div>
                        {/* <div className="p-field-checkbox">
                            <Controller name="remember" control={control} render={({ field, fieldState }) => (
                                <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} />
                            )} />
                            <label htmlFor="remember" className={classNames({ 'p-error': errors.remember })}>Remember me?</label>
                        </div> */}

                        <div className="error-msg">{errorMsg}</div>

                        <Button type="submit" label="Login" className="p-mt-2" onClick={() => setErrorMsg('')} />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginForm