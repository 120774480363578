import React from 'react';
import { Header, SideMenu, Main } from './components'
import {Login} from './pages';
import AppProvider from './hooks/AppContext'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie';

import './styles/override-nova-theme.css';  // custom default nova-accent/theme.css of the Framework
// import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';

function App() {
  return (
    <div className="wrapper">
      <CookiesProvider>
        <Router>
          <Switch>
            <Route exact path='/' component={Login} />
            {/* Phải đặt Router ngoài này để nó bọc đc cái Sidebar thì mới dùng <Link/> đc */}
            <AppProvider>
              <Header />
              <SideMenu />
              <Main />  {/* Switch Route các trang ở trong <Main/> */}
            </AppProvider>
          </Switch>
        </Router>
      </CookiesProvider>
    </div>
  );
}

export default App;