import React, { useRef, useState } from 'react'
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Ripple } from 'primereact/ripple';
import { Tag } from 'primereact/tag'
import { useRewards } from '../hooks/RewardsContext'
import { useApp } from '../../../../hooks/AppContext'
import { bookingStatuses as listBookingStatuses } from '../../../../utils/constants'
import { parseDateToString } from '../../../../utils/DateTime'
import { actionTypes, filterTypes } from '../hooks/filter-types'
import Highlighter from "react-highlight-words"
import './TableRewards.css'
import { dialogTypes } from '../prepareData';
import moment from 'moment-timezone';

function TableRewards(props) {
  const { data, totalPage, totalElements, refreshData, setRewardsAccountData, toggleDialogs, //toastMsg
  } = props

  const { params, dispatchParams, page, limit,
    phone, fullName, setFilter } = useRewards()
  const { getCountPendingRewards } = useApp()

  // const [lastUpdatedTimes, setlastUpdatedTimes] = useState(null)
  const dt = useRef(null);

  const [selectedRow, setSelectedRow] = useState(null)

  //=============Handle actions and dispatch to Reducer (filter by back-end)=============
  const timeoutRef = useRef(null)

  const onPhoneChange = (event) => {
    const phoneNo = event?.target?.value
    setFilter(filterTypes.PHONE, phoneNo)

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      dispatchParams({
        type: actionTypes.FILTER_PHONE,
        payload: phoneNo
      })
      getCountPendingRewards(listBookingStatuses.pending)
    }, 350)
  }
  const onCusNameChange = (event) => {
    const cusName = event?.target?.value
    setFilter(filterTypes.NAME, cusName)

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      dispatchParams({
        type: actionTypes.FILTER_NAME,
        payload: cusName
      })
      getCountPendingRewards(listBookingStatuses.pending)
    }, 350)
  }
  // const onDateRangeFilterChange = (event) => {
  //   // gọi reducer truyền vào API
  //   // console.log('event.value = ', event.value);
  //   const selectedRange = event.value
  //   setRange(selectedRange)   // event.value = [fromDate, toDate] | fromDate, toDate default = null

  //   if (selectedRange) {
  //     // console.log('date range = ', selectedRange);
  //     if (selectedRange[0]) {
  //       dispatchParams({
  //         type: actionTypes.FILTER_FROM_DATE,
  //         payload: `${parseDateToString(selectedRange[0], 'YYYY-MM-DD')} 00:00:00`
  //       })
  //       setFilter(filterTypes.FROM_DATE, selectedRange[0])
  //       getCountPendingRewards(listBookingStatuses.pending)
  //     }

  //     if (selectedRange[1]) {
  //       dispatchParams({
  //         type: actionTypes.FILTER_TO_DATE,
  //         payload: `${parseDateToString(selectedRange[1], 'YYYY-MM-DD')} 23:59:59`
  //       })
  //       setFilter(filterTypes.TO_DATE, selectedRange[1])
  //     } else {
  //       dispatchParams({
  //         type: actionTypes.FILTER_TO_DATE,
  //         payload: `${parseDateToString(selectedRange[0], 'YYYY-MM-DD')} 23:59:59`
  //       })
  //       setFilter(filterTypes.TO_DATE, selectedRange[0])
  //     }
  //   }
  // }
  // const onDateRangeFilterClear = (event) => {
  //   setRange(event.value)
  //   setFilter(filterTypes.FROM_DATE, null)
  //   setFilter(filterTypes.TO_DATE, null)

  //   dispatchParams({
  //     type: actionTypes.FILTER_FROM_DATE,
  //     payload: null
  //   })
  //   dispatchParams({
  //     type: actionTypes.FILTER_TO_DATE,
  //     payload: null
  //   })
  //   getCountPendingRewards(listBookingStatuses.pending)
  // }

  // const onStatusFilterChange = (event) => {
  //   const selectedStatus = event.value
  //   setFilter(filterTypes.STATUS, selectedStatus);
  //   dispatchParams({
  //     type: actionTypes.FILTER_STATUS,
  //     payload: selectedStatus
  //   })
  //   getCountPendingRewards(listBookingStatuses.pending)

  //   // dt.current.filter(event.value, 'status', 'equals');
  // }

  // ======================== Export Excel ========================
  const handleExportExcel = () => {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx?.utils?.json_to_sheet(data);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx?.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAsExcelFile(excelBuffer);
    });
  }

  const saveAsExcelFile = buffer => {
    import('file-saver').then(module => {
      if (module && module?.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module?.default?.saveAs(data, 'DNS_Rewards_' + moment().format('DDMMYY_HHmmss') + EXCEL_EXTENSION);
      }
    });
  }

  //========================Render custom elements (components)========================
  const renderHeader = () => {
    return (
      <div className="table-header">
        <span className="header-title">List of Rewards Accounts</span>
        <Button label="Refresh" icon="pi pi-refresh" className="sm-btnRefresh"
          onClick={() => {
            refreshData(params)
            setSelectedRow(null)
            getCountPendingRewards(listBookingStatuses.pending)
          }}
        />
        {/* <Button label="Add new" icon="pi pi-plus" className="btnAdd sm-btnAdd"
          onClick={() => toggleDialogs.setOpenAddAccountDialog(true)}
        /> */}
        {/* <Button label="Excel" icon="pi pi-download" className="btn-export sm-btn-export"
          onClick={handleExportExcel}
          style={{ marginLeft: '0.5rem' }}
        /> */}
      </div>
    );
  }

  //--------------------------Element body template--------------------------
  const countNumBodyTemplate = (rowData, index) => {
    // console.log({ rowData, index: index?.rowIndex });

    return (
      <React.Fragment>
        <span className="p-column-title">#</span>
        {/* <span>{rowData?.id}</span>  {/.*Tìm lại công thức tính # *./} */}
        <span style={!rowData?.isConfirmed ? { fontWeight: 'bold' } : {}}>
          {page * (data.per_page || 15) + index?.rowIndex + 1}
        </span>
      </React.Fragment>
    );
  }

  const phoneBodyTemplate = rowData => {
    return (
      <React.Fragment>
        <span className="p-column-title">Phone</span>
        <div className='d-flex justify-content-between'>
          <span className="cusPhone ml-1">
            <Highlighter
              highlightClassName="YourHighlightClass"
              searchWords={[phone]}
              autoEscape={true}
              textToHighlight={rowData?.phone || ''}
              highlightStyle={{ backgroundColor: 'rgba(255, 192, 159, 0.75)' }}
            />
          </span>

          {!rowData?.isConfirmed && (
            <Tag className="mr-1" severity="success" value="New" rounded />
          )}
        </div>
      </React.Fragment>
    );
  }
  const cusNameBodyTemplate = rowData => {
    return (
      <React.Fragment>
        <span className="p-column-title">Customer Info</span>
        <span className="cusName ml-1" style={!rowData?.isConfirmed ? { fontWeight: 'bold' } : {}}>
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[fullName]}
            autoEscape={true}
            textToHighlight={rowData?.user?.filter(item => item !== null)?.join(', ') || ''}
            highlightStyle={{ backgroundColor: 'rgba(255, 192, 159, 0.75)' }}
          />
        </span>
      </React.Fragment>
    );
  }
  const contentBodyTemplate = rowData => (
    <React.Fragment>
      <span className="p-column-title">Date</span>
      <span style={!rowData?.isConfirmed ? { fontWeight: 'bold' } : {}}>
        300 points get $10 off
      </span>
    </React.Fragment>
  )
  const dateBodyTemplate = rowData => (
    <React.Fragment>
      <span className="p-column-title">Date</span>
      <span style={!rowData?.isConfirmed ? { fontWeight: 'bold' } : {}}>
        {rowData?.lastModifedDate
          ? (
            <>
              {parseDateToString(rowData?.lastModifedDate, 'MMM DD, YYYY')}
              {/* <br /> */}
              . . .
              {parseDateToString(rowData?.lastModifedDate, 'h:mm A')}
            </>
          )
          : ''}
      </span>
    </React.Fragment>
  )
  const pointBodyTemplate = rowData => (
    <React.Fragment>
      <span className="p-column-title">Current Points</span>
      <span style={!rowData?.isConfirmed ? { fontWeight: 'bold' } : {}}>
        {rowData?.reward}
      </span>
    </React.Fragment>
  )
  const totalPointsBodyTemplate = rowData => (
    <React.Fragment>
      <span className="p-column-title">Total Points</span>
      <span style={!rowData?.isConfirmed ? { fontWeight: 'bold' } : {}}>
        {rowData?.total}
      </span>
    </React.Fragment>
  )

  const actionBodyTemplate = rowData => (
    <Button id="btnEdit" type="button" icon="pi pi-pencil" className="p-button-warning p-button-rounded"
      onClick={() => {
        setSelectedRow(rowData)
        setRewardsAccountData(rowData)
        toggleDialogs.setOpenDetailDialog(true)
        toggleDialogs.setDialogType(dialogTypes.UPDATE)
      }}
    />
  )

  //--------------------------Filters--------------------------
  // Customer phone filter
  const renderPhoneFilter = () => {
    return <InputText type="search" value={phone} onChange={(e) => onPhoneChange(e)}
      placeholder="Search by phone" style={{ width: '100%' }} />
  }

  // Customer name filter
  const renderNameFilter = () => {
    return <InputText type="search" value={fullName} onChange={(e) => onCusNameChange(e)}
      placeholder="Search by name" style={{ width: '100%' }} />
  }

  // Appointment time (date range) filter
  // const monthNavigatorTemplate = (e) => {
  //   return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
  // }
  // const yearNavigatorTemplate = (e) => {
  //   return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2" style={{ lineHeight: 1 }} />;
  // }
  // const renderDateRangeFilter = () => {
  //   return <Calendar value={range} onChange={onDateRangeFilterChange}
  //     placeholder="From Date - To Date" dateFormat="mm/dd/yy"
  //     selectionMode="range" readOnlyInput showButtonBar
  //     monthNavigator yearNavigator yearRange="2010:2030"
  //     monthNavigatorTemplate={monthNavigatorTemplate}
  //     yearNavigatorTemplate={yearNavigatorTemplate}
  //     className="p-column-filter"
  //     onClearButtonClick={onDateRangeFilterClear}
  //   />
  // }

  //==========================Define custom elements' variables==========================
  const header = renderHeader();
  const phoneFilterElement = renderPhoneFilter();
  const cusNameFilterElement = renderNameFilter();
  // const dateRangeFilterElement = renderDateRangeFilter();

  const tHeadColumnGroup = <ColumnGroup>
    <Row>
      {/* <Column selectionMode="multiple" style={{ width: '2.5rem' }} rowSpan={2} /> */}
      <Column header="#" headerStyle={{ width: '2rem', textAlign: 'center' }} rowSpan={2} />
      <Column header="Phone" headerStyle={{ width: '17%', textAlign: 'center' }} />
      <Column header="Name(s)" headerStyle={{ width: '17%', textAlign: 'center' }} />
      <Column header="Reward Content" headerStyle={{ width: '17%', textAlign: 'center' }} rowSpan={2} />
      <Column header="Date" headerStyle={{ width: '17%', textAlign: 'center' }} rowSpan={2} />
      {/* sortable sortField="lastModifedDate" */}
      <Column header="Current Points" headerStyle={{ width: '10%', textAlign: 'center' }} rowSpan={2} />
      <Column header="Total Points" headerStyle={{ width: '10%', textAlign: 'center' }} rowSpan={2} />
      <Column header="" headerStyle={{ width: '2rem', textAlign: 'center' }} rowSpan={2} />
    </Row>
    <Row>
      <Column filter filterField="phone" filterElement={phoneFilterElement} />
      <Column filter filterField="user" filterElement={cusNameFilterElement} />
      {/* <Column filter filterElement={dateRangeFilterElement} /> */}
      {/* <Column filter filterField="current points" filterElement={range..from..to} /> */}

    </Row>
  </ColumnGroup>
  // const loadingBody = <Loading />

  //======================================Paginator======================================
  const handleChangePage = (e) => {
    if (e === page || page === e?.target?.innerText - 1) return
    let newPage = e
    if (e.target?.innerText)
      newPage = parseInt(e.target.innerText) - 1
    setFilter(filterTypes.PAGE, newPage);

    console.log('handleChangePage -- event: ', e);

    dispatchParams({
      type: actionTypes.CHANGE_PAGE,
      payload: {
        page: newPage,
        limit: limit
      }
    })
  };

  const handleChangeLimit = (event) => {
    setFilter(filterTypes.LIMIT, parseInt(event?.target?.value, 15));   // hoặc "event?.value" cũng đc
    setFilter(filterTypes.PAGE, 0);
    // setLimit(event?.target?.value)
    // setPage(0)

    console.log('handleChangeLimit -- event: ', event);

    dispatchParams({
      type: actionTypes.CHANGE_PAGE,
      payload: {
        page: 0,
        limit: parseInt(event.target.value, 15)
      }
    })
  };

  const paginatorTemplate = {
    layout: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
    FirstPageLink: (options) => {
      return (
        <Button
          type="button"
          className={options.className}
          onClick={e => {
            options.onClick(e)
            handleChangePage(0)
          }}
          disabled={options.disabled}
        >
          <i className="pi pi-angle-double-left" />
          <Ripple />
        </Button>
      );
    },
    PrevPageLink: (options) => {
      return (
        <Button
          type="button"
          className={options.className}
          onClick={e => {
            options.onClick(e)
            handleChangePage(page - 1)
          }}
          disabled={options.disabled}
        >
          {/* <span className="p-p-3">Previous</span> */}
          <i className="pi pi-angle-left" />
          <Ripple />
        </Button>
      );
    },
    NextPageLink: (options) => {
      return (
        <Button
          type="button"
          className={options.className}
          onClick={e => {
            options.onClick(e)
            handleChangePage(page + 1)
          }}
          disabled={options.disabled}
        >
          {/* <span className="p-p-3">Next</span> */}
          <i className="pi pi-angle-right" />
          <Ripple />
        </Button>
      );
    },
    LastPageLink: (options) => {
      return (
        <Button
          type="button"
          className={options.className}
          onClick={e => {
            options.onClick(e)
            handleChangePage(totalPage - 1)
          }}
          disabled={options.disabled}
        >
          <i className="pi pi-angle-double-right" />
          <Ripple />
        </Button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span className={className} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }

      return (
        <Button
          type="button"
          className={options.className}
          // onClick={options.onClick}
          // onClick={handleChangePage}
          onClick={e => {
            options.onClick(e)
            handleChangePage(e)
          }}
        >
          {options.page + 1}
          <Ripple />
        </Button>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 15, value: 15 },
        { label: 30, value: 30 },
        { label: 50, value: 50 }
      ];
      return (
        <Dropdown
          value={limit}
          // value={options.value}
          options={dropdownOptions}
          // onChange={options.onChange}
          appendTo={document.body}
          onChange={handleChangeLimit}
        />
      );
    }
  };
  //=====================================End Paginator====================================

  return (
    <div className="datatable-rewards">
      <DataTable ref={dt} value={data} dataKey="phone" rowHover  //scrollable scrollHeight="200px"
        className="p-datatable-sm p-datatable-rewards"
        header={header} headerColumnGroup={tHeadColumnGroup}
        selection={selectedRow} selectionMode="single"
        onSelectionChange={e => setSelectedRow(e.value)} //cellSelection 
        onRowDoubleClick={e => {
          setRewardsAccountData(e?.data)
          toggleDialogs.setOpenDetailDialog(true)
          toggleDialogs.setDialogType(dialogTypes.VIEW)
        }}  // Phải dùng double click để tránh mở 2 dialogs cùng 1 lúc
        emptyMessage="No rewards accounts found"
        paginator paginatorTemplate={paginatorTemplate}
        first={0} rows={limit} lazy
        currentPageReportTemplate="Showing {first}-{last} of {totalRecords} entries"
        totalRecords={totalElements}
      >
        <Column body={countNumBodyTemplate} style={{ textAlign: 'center' }} />
        <Column field="phone" body={phoneBodyTemplate} />
        <Column body={cusNameBodyTemplate} />
        <Column body={contentBodyTemplate} style={{ textAlign: 'center' }} />
        <Column field="lastModifedDate" body={dateBodyTemplate} style={{ textAlign: 'center' }} />
        <Column field="reward" body={pointBodyTemplate} style={{ textAlign: 'center' }} />
        <Column field="total" body={totalPointsBodyTemplate} style={{ textAlign: 'center' }} />
        <Column body={actionBodyTemplate} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
      </DataTable>
    </div>
  )
}

export default TableRewards
