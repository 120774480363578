import React from 'react'
import { ContentContainer } from '../../components'

function Dashboards() {
    return (
        <ContentContainer pageName="Dashboards">
            Coming soon...
        </ContentContainer>
    )
}

export default Dashboards