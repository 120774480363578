import React, { useState } from 'react'
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import {
    MyFieldset,
    Title,
    Content,
    Legend, Row,
    ServiceFieldSetWrapper,
    ServiceGroupRow,
    ServiceGroupName,
    ServiceRow,
    ServiceName,
    ServiceOptionRow,
    ServiceOptionTitle,
    ServiceOptionName
} from './DetailDialogItems';
// import { bookingStatuses as bookingStatus } from '../../../utils/constants'
// import { updateStatus } from '../../../services/BookingApi'
import { parseDateToString, parseDateToUTC, getUTCTimeZoneCode } from '../../../utils/DateTime'
import './dialog.css'

function DetailDialog(props) {
    const { openDialog, setOpenDialog, appointment } = props    //, refreshData, toast, setLoading
    // const [selectedStatus, setSelectedStatus] = useState(null)
    // const bookingStatuses = appointment?.status !== 'pending'
    //     ? Object.values(bookingStatus).slice(1)
    //     : Object.values(bookingStatus)
    // const bookingStatuses = Object.values(bookingStatus).slice(1)

    // const showSuccessToast = () => {
    //     toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Updated booking status successfully', life: 3000 });
    // }
    // const showErrorToast = () => {
    //     toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Updated booking status failed', life: 3000 });
    // }

    const onHide = () => {
        setOpenDialog(false)
        // setSelectedStatus(null)
    }

    // const handleUpdateStatus = () => {
    //     updateStatus(appointment?.id, selectedStatus).then(res => {
    //         // console.log('update xong goi: ', res);
    //         showSuccessToast()
    //         setLoading(true)
    //         refreshData()
    //     }).catch(error => {
    //         if (error?.response) {
    //             console.error('Error at DetailDialog: ', error?.response);
    //             showErrorToast()
    //         }
    //     })
    //     onHide()
    // }

    // const statusItemTemplate = (option) => {
    //     return <span className={classNames('appointment-badge', 'status-' + option)} style={{ borderRadius: '3px' }}>{option}</span>
    // }
    const renderFooter = () => {
        return (
            <div>
                <Button label="Close" icon="pi pi-times" onClick={onHide}
                    className="p-button-outlined p-button-plain p-button-sm" />
                {/* <Button label="Save" icon="pi pi-check" onClick={handleUpdateStatus}    // autoFocus
                    disabled={!selectedStatus} className="p-button-success p-button-sm" /> */}
            </div>
        );
    }

    // console.log('Detail dialog ---- selectedRow = ', appointment);

    return (
        <Dialog visible={openDialog} onHide={onHide} style={{ width: '65rem', height: '37.5rem' }}
            header="Appointment Details" footer={renderFooter()} className='dialog-appointment'>
            <div className="row dialog-row-info" style={{ padding: '0.25rem 0.3rem 0' }}>
                <div className="col-md-6 col-sm-12">
                    <div className="row">
                        <div className="col-12">
                            {/* Booking Info */}
                            <MyFieldset legend={<Legend>Booking Info</Legend>}>
                                <Row>
                                    <Title>Appt ID</Title>
                                    <Content style={{ fontStyle: 'italic' }}>{appointment?.id}</Content>
                                </Row>
                                <Row>
                                    <Title>Date &amp; Time</Title>
                                    <Content>
                                        {parseDateToString(appointment?.time, 'MMM D, YYYY')}
                                        {''} &nbsp; <span style={{ color: '#848484' }}> | </span> &nbsp; {''}
                                        {parseDateToString(appointment?.time, 'h:mm a')} {' '}
                                        {/* {appointment?.time?.substring(appointment?.time?.indexOf(' '))} {' '} */}
                                        <span style={{ fontSize: '0.85rem', }}>({getUTCTimeZoneCode(appointment?.date)})</span>
                                    </Content>
                                </Row>
                                <Row>
                                    <Title>Status</Title>
                                    <Content>
                                        <span className={classNames('appointment-badge', 'status-' + appointment?.status)}>
                                            {appointment?.status}
                                        </span>
                                    </Content>
                                </Row>
                                <Row>
                                    <Title>Technician</Title>
                                    {/**Dùng chips */}
                                    <Content>{appointment?.technician?.name}</Content>
                                </Row>
                                <Row>
                                    <Title>Note</Title>
                                    <Content>
                                        {appointment?.note ? appointment?.note :
                                            <span style={{ color: '#848484', fontStyle: 'italic' }}>(none)</span>
                                        }
                                    </Content>
                                </Row>
                            </MyFieldset>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '0.6rem' }}>
                        <div className="col-12">
                            {/* Customer Info */}
                            <MyFieldset legend={<Legend>Customer Info</Legend>}>
                                <Row>
                                    <Title>Your Name</Title>
                                    <Content>{appointment?.name}</Content>
                                </Row>
                                <Row>
                                    <Title>Phone No.</Title>
                                    <Content>{appointment?.phone}</Content>
                                </Row>
                                <Row>
                                    <Title>Email</Title>
                                    <Content>
                                        {appointment?.email ? appointment?.email :
                                            <span style={{ color: '#a0a0a0', fontStyle: 'italic' }}>(none)</span>
                                        }
                                    </Content>
                                </Row>
                            </MyFieldset>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    {/* Service Info */}
                    <MyFieldset legend={<Legend>Booked Services</Legend>} id="bookingFieldset" style={{ minHeight: '60vh', paddingBottom: '0.5rem' }}>
                        <ServiceFieldSetWrapper>
                            {appointment?.options?.map(group => (
                                <ServiceGroupRow key={group?.key}>
                                    <ServiceGroupName>{group?.label}</ServiceGroupName>
                                    {group?.children?.map((service, indexS) => (
                                        <ServiceRow key={indexS}>
                                            <ServiceName>{indexS + 1}/ &nbsp; {service?.label}</ServiceName>
                                            {service?.children && (
                                                <ServiceOptionRow>
                                                    <ServiceOptionTitle>Flavor: &nbsp;</ServiceOptionTitle>
                                                    {service?.children?.map((option, indexO) => (
                                                        <ServiceOptionName key={indexO}>
                                                            {option?.label}
                                                            {indexO < service.children.length - 1 && ', '}
                                                        </ServiceOptionName>
                                                    ))}
                                                </ServiceOptionRow>
                                            )}
                                        </ServiceRow>
                                    ))}
                                </ServiceGroupRow>
                            ))}
                            {appointment?.options?.length < 1 && (
                                <span style={{ color: '#a0a0a0', fontStyle: 'italic', fontSize: '0.9rem' }}>No services selected</span>
                            )}
                        </ServiceFieldSetWrapper>
                    </MyFieldset>
                </div>
            </div>
        </Dialog>
    )
}

export default DetailDialog
