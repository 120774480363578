import React, { useState } from 'react'
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { bookingStatuses as bookingStatus } from '../../../utils/constants'
import { updateStatus } from '../../../services/BookingApi'
import { useBooking } from '../hooks/BookingContext'
import { useApp } from '../../../hooks/AppContext';
import { useHistory } from 'react-router-dom';
import './dialog.css'

function UpdateStatusDialog(props) {
    const { openDialog, setOpenDialog, appointment, setSelectedRow, refreshData, toast } = props    //, setLoading
    const { params } = useBooking()
    const { getCountPendingBooking, request4RefreshToken } = useApp()
    const history = useHistory()

    const [selectedStatus, setSelectedStatus] = useState(null)
    const bookingStatuses = appointment?.status !== 'pending'
        ? Object.values(bookingStatus).slice(1)
        : Object.values(bookingStatus)
    // const bookingStatuses = Object.values(bookingStatus).slice(1)

    const showSuccessToast = () => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Updated booking status successfully', life: 3000 });
    }
    const showErrorToast = () => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Updated booking status failed', life: 3000 });
    }

    const onHide = () => {
        setOpenDialog(false)
        setSelectedStatus(null)
    }

    const handleUpdateStatus = () => {
        // console.log('appointmentId = ', appointment?.id);
        // console.log('selectedStatus = ', selectedStatus);
        console.log(params);

        updateStatus(appointment?.id, selectedStatus).then(res => {
            // console.log('update xong goi: ', res);
            showSuccessToast()
            // setLoading(true)
            refreshData(params)
            setSelectedRow(null)
            getCountPendingBooking(bookingStatus.pending)
        }).catch(error => {
            if (error?.response?.status === 403 && error?.response?.data?.error_message?.includes('The Token has expired')) {
                request4RefreshToken(handleUpdateStatus)
            } else if (error?.response?.status === 403 && error?.response?.data?.error === 'Forbidden') {
                history.replace('/')
                // alert('You are not allowed to use this feature. Please login again.')
            } else {
                console.error('Error at UpdateStatusDialog: ', error);
                showErrorToast()
            }
        })
        onHide()
    }

    const statusItemTemplate = (option) => {
        return <span className={classNames('appointment-badge', 'status-' + option)} style={{ borderRadius: '3px' }}>{option}</span>
    }
    const renderFooter = () => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={onHide}
                    className="p-button-outlined p-button-plain p-button-sm" />
                <Button label="Save" icon="pi pi-check" onClick={handleUpdateStatus}    // autoFocus
                    disabled={!selectedStatus} className="p-button-success p-button-sm" />
            </div>
        );
    }

    return (
        <Dialog visible={openDialog} onHide={onHide} style={{ width: '25rem' }}
            header="Update Appointment Status" footer={renderFooter()} className='dialog-appointment'>
            <div className="row dialog-row-info">
                <div className="col-sm-5"><span className="dialog-content-label">Appointment ID</span></div>
                <div className="col-sm-7">
                    <span style={{ fontSize: '0.9rem' }}>{appointment?.id}</span>
                </div>
            </div>
            <div className="row dialog-row-info">
                <div className="col-sm-5"><span className="dialog-content-label">Current status</span></div>
                <div className="col-sm-7">
                    <span className={classNames('appointment-badge', 'status-' + appointment?.status)}>
                        {appointment?.status}
                    </span>
                </div>
            </div>
            <div className="row dialog-last-row">
                <div className="col-sm-5"><span className="dialog-content-label">New status</span></div>
                <div className="col-sm-7 ">
                    <Dropdown value={selectedStatus} options={bookingStatuses} onChange={(e) => setSelectedStatus(e.value)}
                        itemTemplate={statusItemTemplate} placeholder="Select a status" className="p-column-filter status-dropdown"
                        style={{ textTransform: 'capitalize' }}
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default UpdateStatusDialog
