import { default as Api } from './ApiConfig'
import queryString from 'query-string'
import ENDPOINT from '../config/endpoints'

// API for Appointments
export async function getAppointments(params) {
    // xử lý filters ở đây
    const newParams = { ...params }
    const techIds = queryString.stringify({ technicianIds: newParams?.technicianIds }, { arrayFormat: 'comma' });
    const serviceIds = queryString.stringify({ serviceIds: newParams?.serviceIds }, { arrayFormat: 'comma' });
    delete newParams?.technicianIds
    delete newParams?.serviceIds
    // queryString.stringify({ technicianIds: params?.technicianIds }, { arrayFormat: 'comma' });
    // queryString.stringify({ foo: [1, 2, 3] }, { arrayFormat: 'comma' });
    // //=> 'foo=1,2,3'

    const paramString = queryString.stringify(newParams)
    let url = `${ENDPOINT.APPOINTMENTS}?${paramString}`

    // let url = `/appointments?page=${params?.page}&limit=${params?.limit}&sortBy=${params?.sortBy}&direction=${params?.direction}`

    url = techIds ? url.concat(`&${techIds}`) : url
    url = serviceIds ? url.concat(`&${serviceIds}`) : url

    // console.log('url = ', url);

    // const response = await Api.get(url)
    // return (await response.data)     // đã config ".data" bên file ApiConfig rồi nên ở đây ko cần nữa
    return await Api.get(url)
}

export async function updateStatus(id, status) {
    const response = await Api.patch(`${ENDPOINT.APPOINTMENTS}/${id}?status=${status}`)
    return response
    // return (await response.data)     // đã config ".data" bên file ApiConfig rồi nên ở đây ko cần nữa
}

// API for Booking time/ (available time slots for booking)
export async function getAllSlots() {
    const response = await Api.get(`${ENDPOINT.OPENING_SLOTS}`)
    return response
}
export async function getSlotsByDay(day) {
    const response = await Api.get(`/time?date=${day}`)
    return response
}
export async function updateAvailableSlots(data) {  //dayName, slotIds
    const response = await Api.post(`${ENDPOINT.OPENING_TIME}`, data)
    return response
}
export async function applyDefault(day) {  //dayName, slotIds
    const response = await Api.patch(`${ENDPOINT.OPENING_TIME}/${day}`)
    return response
}
