// import axiosClient from './ApiConfig'
import axios from 'axios'
import { Cookies } from 'react-cookie'
import ENDPOINT from '../config/endpoints'

const authenApi = {
    login: async (userData) => {
        const config = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }

        const params = new URLSearchParams();
        params.append('username', userData?.username);
        params.append('password', userData?.password);

        const response = await axios.post(`${ENDPOINT.BASE_URL}${ENDPOINT.LOGIN}`, params, config)
        return response.data
    },
    refreshToken: async () => {
        const cookie = new Cookies()
        const refreshToken = cookie.get('user')?.refresh_token

        const config = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken}`
            }
        }
        const response = await axios.get(`${ENDPOINT.BASE_URL}${ENDPOINT.REFRESH_TOKEN}`, config)
        return response.data
    }
}

export default authenApi;