import React, { useState } from 'react'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import TimePicker from './TimePicker'
import { parseDateToString } from '../../../../utils/DateTime'
import './CustomOption.css'

function CustomOption({ option, toast }) {
    const [selectedDate, setSelectedDate] = useState(new Date())

    const monthNavigatorTemplate = (e) => {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
    }
    const yearNavigatorTemplate = (e) => {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2" style={{ lineHeight: 1 }} />;
    }

    return (
        <div className="custom-option">
            <div className="custom-option__calendar">
                <Calendar value={selectedDate} onChange={e => setSelectedDate(e.value)}
                    // minDate={new Date()}
                    // minDate={new Date(salonNow.format('YYYY-MM-DD'))}
                    inline showButtonBar
                    selectOtherMonths
                    panelStyle={{ padding: '0 0.857rem' }}
                    monthNavigator yearNavigator yearRange="2015:2030"
                    monthNavigatorTemplate={monthNavigatorTemplate}
                    yearNavigatorTemplate={yearNavigatorTemplate}
                    clearButtonClassName="calendarClearButton"
                />
            </div>
            <TimePicker option={option} toast={toast} day={parseDateToString(selectedDate, 'YYYY-MM-DD')} />
            {/* setDay={setSelectedDate}  */}
        </div>
    )
}

export default CustomOption
