import styled from 'styled-components'
import { Fieldset } from 'primereact/fieldset'

export const MyFieldset = styled(Fieldset)`
    min-height: 26vh;
    /* padding-bottom: 0.5rem; */
    margin: 0.15rem;
    /* border: 2px solid #FFC09F; */
    border: none;
    background-color: #ffc19f30;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    
    & input {
      padding: 0 5px 2px;
      border-radius: 3px;
    }
`
export const Legend = styled.h6`
    font-weight: 700;
    // Ngoài chỗ này, legend còn đc CSS trong file dialog.css
`

export const Row = styled.div`
    width: 100%;
    padding: 0 0.25rem;
    display: grid;
    grid-template-columns: 2fr 5fr;

    @media screen and (max-width: 960px) {
      grid-template-columns: 1fr 3fr;
    }
`
export const Title = styled.p`
    min-width: 90px;
    font-size: 0.9rem;
    font-weight: 600;
`
export const Content = styled.div`
    font-size: 0.9rem;
`