import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

import * as API from '../../../../services/CustomersApi'
import { useCustomer } from '../../hooks/CustomerContext'
import { checkinStatus } from '../../prepareData'
// import { getHistory, updatePointsHistory } from '../../../../services/CustomersApi'

import { BtnCheckin, BtnCheckout } from './StyledItems'
import { Profile, Rewards, History } from './components'
import '../dialog.scss'

function DialogCustomerDetail(props) {
  const { open, setOpen, data, toastMsg } = props    // , type, setLoading

  const { filters, getListCustomers } = useCustomer()

  const [loading, setLoading] = useState({
    key: '', value: false
  })

  const [history, setHistory] = useState(null)

  // input fields in fieldset 'Profile'
  const [profile, setProfile] = useState({
    phone: data?.phone,
    email: data?.email,
    birthdate: data?.birthdate
  })
  const [rewards, setRewards] = useState({
    gainingPoints: 0,
    redeemingPoints: 0,
    bill: 0,
  })

  useEffect(() => {
    API.getHistory(data?.phone).then(res => {
      if (res) {
        const _history = res?.filter(item =>
          (+item?.price > 0) || (+item?.reward > 0)
          // || item?.status === checkinStatus.CHECK_IN.value
        )
        // console.log("_history: ", _history);
        setHistory(_history?.reverse())
        // setHistory(res)
      }
    })
      .catch(error => {
        toastMsg?.showErrorToast("Get customer's history failed")
        console.log('Error at getHistory() in DialogCustomerDetail:', error)
      })
  }, [])


  const onHide = () => {
    setOpen(false)
    // setSelectedStatus(null)
  }

  const handleCheckIn = () => {
    API.confirmCheckIn(data?.id).then(() => {
      getListCustomers(filters)
      toastMsg?.showSuccessToast('Checkin successfully')
      setOpen(false)
    })
      .catch(err => {
        toastMsg?.showErrorToast('Checkin failed')
        console.log('Error at confirmCheckIn() in DialogCustomerDetail:', err)
      })
  }

  const handleCheckOut = () => {
    const reqData = {
      reward: rewards?.gainingPoints ?? 0,
      discount: rewards?.redeemingPoints ?? 0,
      price: rewards?.bill ?? 0,
    }

    API.checkOut(data?.id, reqData)
      .then(res => {
        toastMsg?.showSuccessToast('Checkout successfully')
        getListCustomers(filters)

        setTimeout(() => setOpen(false), 500)
      })
      .catch(err => {
        toastMsg?.showErrorToast('Checkout failed')
        console.log('Error at checkOut() in DialogCustomerDetail:', err)
      })
  }

  const renderFooter = () => {
    return (
      <div>
        {data?.status === checkinStatus.REQUEST.value && (
          <BtnCheckin
            label="Check in"
            icon="pi pi-sign-in"
            onClick={handleCheckIn}
            className="p-button-sm"
          />
        )}
        {data?.status === checkinStatus.CHECK_IN.value && (
          <BtnCheckout
            label="Check out"
            icon="pi pi-sign-out"
            onClick={handleCheckOut}
            className="p-button-sm"
          />
        )}
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={onHide}
          className="p-button-outlined p-button-plain p-button-sm"
        />
      </div>
    );
  }

  return (
    <Dialog visible={open} onHide={onHide}
      header="Customer Info"
      footer={renderFooter()}
      blockScroll
      className='dialog-customers'
      breakpoints={{
        '1200px': '1024px',
        '1024px': '960px',
        '960px': '768px',
        '767px': '640px',
        '640px': '580px',
        '580px': '100vw'
      }}
    >
      <div className="row dialog-row-info">
        {/* Customer Profile */}
        <div className="col-md-6 col-sm-12">
          <Profile
            data={data}
            inputsValue={profile}
            setInputsValue={setProfile}
            toastMsg={toastMsg}
          />
        </div>

        {/* Rewards History */}
        <div className="col-md-6 col-sm-12">
          <Rewards
            data={data}
            inputsValue={rewards}
            setInputsValue={setRewards}
            toastMsg={toastMsg}
          />
        </div>
      </div>

      <div className="row dialog-row-info mt-2">
        <div className="col-sm-12">
          <History
            // data={data}
            // profile={profile} rewards={rewards}
            history={history} setHistory={setHistory}
            loading={loading} setLoading={setLoading}
            toastMsg={toastMsg}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default DialogCustomerDetail
