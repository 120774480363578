import React from 'react'
import { ContentContainer } from '../../components'

function CustomerMessages() {
    return (
        <ContentContainer pageName="Customer's Messages">
            Coming soon...
        </ContentContainer>
    )
}

export default CustomerMessages