import React from 'react'
import { useApp } from '../hooks/AppContext'
import { Link, useLocation } from 'react-router-dom'

function SideMenu() {
  const location = useLocation()
  const path = location?.pathname

  const { countPendingBooking, countPendingRewards } = useApp()

  // className ko có tính chất "động", tức là nó ko đc trigger nên ko gọi đc function này
  // const generateClassName = (path) => {
  //     console.log('location = ', location.pathname);
  //     console.log('path = ', path);
  //     if (path === location.pathname)
  //         return 'nav-link active'

  //     return 'nav-link'
  // }

  return (
    <div>
      {/* Main Sidebar Container */}
      <aside className="main-sidebar main-sidebar-custom sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/dashboards" className="brand-link">
          {/* <img src="../../dist/img/AdminLTELogo.png" alt="Logo" className="brand-image img-circle elevation-1" style={{ opacity: '.8' }} /> */}
          <img src="../../logo.jpg" alt="Logo" className="brand-image img-circle elevation-1" style={{ opacity: '.8' }} />
          <span className="brand-text font-weight-light">Deluxe Nails &amp; Spa</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          {/* SidebarSearch Form */}
          {/* <div className="form-inline">
                        <div className="input-group" data-widget="sidebar-search">
                            <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-sidebar">
                                    <i className="fas fa-search fa-fw" />
                                </button>
                            </div>
                        </div>
                    </div> */}
          {/* Sidebar Menu */}
          <nav className="mt-2 my-nav-sidebar">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              {/* Add icons to the links using the .nav-icon class
                                with font-awesome or any other icon font library */}
              <li className="nav-item">
                <Link to="/dashboards" className={path?.startsWith('/dashboards') ? 'nav-link active' : 'nav-link'}>
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>Dashboards</p>
                </Link>
              </li>

              {/* ========================== CUSTOMER ========================== */}
              <li className="nav-header">CUSTOMERS</li>
              {/* Check-in / Check-out */}
              <li className="nav-item">
                <Link to="/checkin-checkout"
                  className={path?.startsWith('/checkin-checkout') ? 'nav-link active' : 'nav-link'}
                >
                  <i className="nav-icon fas fa-user-check" />
                  <p>
                    Check-in / Check-out
                    {/* {countInServices > 0 &&
                      <span className="badge badge-primary right">{countInServices}</span>
                    } */}
                  </p>
                </Link>
              </li>

              {/* Appointments */}
              <li className="nav-item">
                {/* <Link to="/appointments" className={() => generateClassName('/appointments')}> */}
                <Link to="/appointments" className={path?.startsWith('/appointments') ? 'nav-link active' : 'nav-link'}>
                  <i className="nav-icon fa fa-calendar-check" aria-hidden="true"></i>
                  <p>
                    Appointments
                    {countPendingBooking > 0 &&
                      <span
                        className="badge badge-primary right"
                        title={'pending booking' + (countPendingBooking > 1 ? 's' : '')}
                      >
                        {countPendingBooking}
                      </span>
                    }
                  </p>
                </Link>
              </li>

              {/* Rewards */}
              {/* <li className="nav-item">
                <Link to="/rewards-history"
                  className={path?.startsWith('/rewards') ? 'nav-link active' : 'nav-link'}>
                  <i className="fas fa-donate nav-icon" />
                  <p>
                    Rewards
                    <i className="right fas fa-angle-left" />

                    {countPendingRewards > 0 &&
                      <span
                        className="badge badge-success right"
                        title={'pending request' + (countPendingRewards > 1 ? 's' : '')}
                      >
                        {countPendingRewards}
                      </span>
                    }
                  </p>
                </Link>

                {/.* Sub menu *./}
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/rewards-history"
                      className={path?.startsWith('/rewards-history')
                        ? 'nav-link sub-nav-link active' : 'nav-link sub-nav-link'}
                    >
                      {/.* <i className="fas fa-history nav-icon" /> *./}
                      <p>Rewards History</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/rewards-content"
                      className={path?.startsWith('/rewards-content')
                        ? 'nav-link sub-nav-link active' : 'nav-link sub-nav-link'}
                    >
                      {/.* <i className="fas fa-star nav-icon"></i> *./}
                      <p>Rewards Content</p>
                    </Link>
                  </li>
                </ul>
              </li> */}

              {/* ========================== MANAGEMENT ========================== */}
              <li className="nav-header">MANAGEMENT</li>
              {/* Booking Time */}
              <li className="nav-item">
                <Link to="/bookingTime" className={path?.startsWith('/bookingTime') ? 'nav-link active' : 'nav-link'}>
                  <i className="fas fa-door-open nav-icon" />
                  <p>Booking Time</p>
                </Link>
              </li>

              {/* Services */}
              <li className="nav-item">
                <Link to="/services" className={path?.startsWith('/services') ? 'nav-link active' : 'nav-link'}>
                  <i className="nav-icon fas fa-hand-sparkles" />
                  <p>Services</p>
                </Link>
              </li>
              {/* <li className="nav-item">
                  <Link to="/technicians" className={path?.startsWith('/technicians') ? 'nav-link active' : 'nav-link'}>
                      <i className="nav-icon fas fa-users" />
                      <p>Technicians</p>
                  </Link>
              </li> */}

              {/* Gallery */}
              <li className="nav-item">
                <Link to="/gallery" className={path?.startsWith('/gallery') ? 'nav-link active' : 'nav-link'}>
                  <i className="nav-icon far fa-image" />
                  <p>Gallery</p>
                </Link>
              </li>

              {/* ========================== BUSINESS ========================== */}
              <li className="nav-header">BUSINESS</li>
              {/* Salon's Profile */}
              <li className="nav-item">
                <Link to="/salonProfile" className={path?.startsWith('/salonProfile') ? 'nav-link active' : 'nav-link'}>
                  <i className="nav-icon fas fa-address-card" />
                  <p>Salon's Profile</p>
                </Link>
              </li>

              {/* Customer's Messages */}
              <li className="nav-item">
                <Link to="/messages" className={path?.startsWith('/messages') ? 'nav-link active' : 'nav-link'}>
                  <i className="nav-icon fas fa-envelope" />
                  <p>Customer's Messages</p>
                </Link>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}

        {/* Copright footer */}
        <div className="sidebar-custom">
          <span style={{ color: '#c2c7d0', fontSize: '0.9rem' }}>
            Copyright &copy; 2021 by <br />
            <strong>
              <a href="https://deluxenailsspacoppell.com/" target="_blank">Deluxe Nails &amp; Spa</a>
            </strong>
            {/* All rights reserved. */}
          </span>
        </div>
      </aside>
    </div>

  )
}

export default SideMenu
