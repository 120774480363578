import styled from 'styled-components'
import { Fieldset } from 'primereact/fieldset'
import { SelectButton } from 'primereact/selectbutton'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'

export const MyFieldset = styled(Fieldset)`
    min-height: 26vh;
    /* padding-bottom: 0.5rem; */
    margin: 0.15rem;
    /* border: 2px solid #FFC09F; */
    border: none;
    background-color: #ffc19f30;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    
    & input {
      padding: 0 5px;
    }
`
export const Legend = styled.h6`
    font-weight: 700;
    // Ngoài chỗ này, legend còn đc CSS trong file dialog.css
`

/*============================== Customer Info ==============================*/
export const ButtonGroupWrapper = styled.div`
    width: fit-content;
    height: 35px;
    margin: -22px 0 16px auto;
`
export const ButtonGroup = styled(SelectButton)`
    & div.p-button {
        padding: 4px 12px;
    }
`
export const InputPoint = styled(InputNumber)`
    display: block;
    width: 89.1%;
    & input {
      width: 89.1%;
      border-radius: 2px;
      border-color: #848484;
    }
`
export const BtnSave = styled(Button)`
    padding: 4px 12px;
`

export const Row = styled.div`
    width: 100%;
    padding: 0 0.25rem;
    display: grid;
    grid-template-columns: 3fr 4fr;
`
export const Title = styled.p`
    font-size: 0.9rem;
    font-weight: 600;
`
export const Content = styled.div`
    font-size: 0.9rem;
`