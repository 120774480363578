import styled from 'styled-components'

export const SuggestedNameWrapper = styled.div`
  /* width: 100%; */
  margin-left: 40px;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
`
export const SuggestedName = styled.div`
  padding: 4px 10px;
  margin-top: 0.5em;
  margin-right: 0.5rem;

  font-size: 0.85rem;
  color: #fff;
  background-color: #FFC09F;
  border-radius: 3px;

  &:hover {
    color: #333;
    font-weight: 500;
    cursor: pointer;

  }
`