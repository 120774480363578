import React from 'react'
import { SelectButton } from 'primereact/selectbutton';
import { options } from '../data'
import './Head.css'

function Head({ option, setOption }) {
    return (
        <div className="head-wrapper">
            <h1 className="head-wrapper__title">Booking Time</h1>
            <SelectButton className="head-wrapper__select-button"
                value={option} options={options} onChange={(e) => {
                    if (e.value !== null && e.value !== undefined)
                        setOption(e.value)
                }} />
        </div>
    )
}

export default Head
