import { default as Api } from './ApiConfig'
import queryString from 'query-string'
import ENDPOINT from '../config/endpoints'

// API for Appointments
export async function getAllCustomers(params) {
  const query = queryString.stringify(params, {
    skipEmptyString: true,
    skipNull: true
  })

  // console.log('query params: ', query);

  return await Api.get(`${ENDPOINT.CUSTOMER}?${query}`)

  // return await Api.get(`${ENDPOINT.CUSTOMER}`, {
  //   params: queryString.stringify(query, {
  //     skipEmptyString: true,
  //     skipNull: true
  //   })
  // })
}

export async function confirmCheckIn(customerId) {
  return await Api.patch(`${ENDPOINT.CUSTOMER}/${customerId}/confirm`)
}

export async function checkOut(customerId, reqData) {
  return await Api.patch(`${ENDPOINT.CUSTOMER}/${customerId}/checkout`, reqData)
}

export async function getHistory(phoneNo) {
  return await Api.get(`${ENDPOINT.CUSTOMER}/${phoneNo}/get-history`)
}

export async function updatePointsHistory(customerId, reqData) {
  return await Api.patch(`${ENDPOINT.CUSTOMER}/${customerId}/update-history`, reqData)
}

export async function updateCustomerInfo(customerId, reqData) {
  return await Api.patch(`${ENDPOINT.CUSTOMER}/${customerId}/update`, reqData)
}

// export async function getListNamesByPhone(phoneNo) {
//   return await Api.get(`${ENDPOINT.CUSTOMER}/${phoneNo}`)
// }