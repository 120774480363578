import moment from "moment-timezone";

export const parseDateToString = (date, format) => {
    return moment(date).format(format);
}

export const parseDateUTCToString = (date, format) => {
    return moment.utc(date).format(format);
}

export const parseDateToUTC = (time, format) => {
    // console.log('......', moment(time).format(format));
    // moment.utc(time).format(format)
    return moment(time).utc().format(format);
}

export const getUTCTimeZoneCode = (date) => {
    return moment(date).tz('America/Chicago').format('z')
    // return String(date).substr(String(date).indexOf('GMT'), 6);
}

export const convertToTimezone = (time, toZone, format, fromZone) => {
    if (fromZone) {
        const fromLocation = moment.tz(time, fromZone)
        const toLocation = fromLocation.clone().tz(toZone)
        return toLocation.format(format);
    }

    return moment(time).tz(toZone).format(format);
}

export const convertDateToDayOfWeek = (date) => {
    return moment(date).format('dddd')
}