import React, { useState, useContext, createContext, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useApp } from '../../../hooks/AppContext'
import { getAllCustomers } from '../../../services/CustomersApi'
import { spaOptions } from '../../../config/constants'
import { filterTypes } from '../prepareData'

const CustomerContext = createContext()

export const useCustomer = () => useContext(CustomerContext)

let initFilters = {
  spa: spaOptions[0]?.value,
  direction: 'DESC',
  sortBy: 'status',
  page: 0,
  limit: 15,
  id: undefined,
  phone: '',
  name: '',
  type: null,
  status: null,
}

function useCustomerProvider() {
  const history = useHistory()

  const [data, setData] = useState(null)  // customerData

  const [filters, setFilters] = useState({ ...initFilters })
  const [changedKey, setChangedKey] = useState(null)

  const [isLoading, setIsLoading] = useState(false)

  const timeoutRef = useRef(null)
  const { request4RefreshToken, setOpenNetworkModal } = useApp()


  const updateFilters = (key, value) => {
    setChangedKey(key)
    setFilters(prevFilters => {
      switch (key) {
        case filterTypes.PAGE:
        case filterTypes.SORT_BY:
        case filterTypes.DIRECTION:
          return {
            ...prevFilters,
            [key]: value
          }

        default:
          return {
            ...prevFilters,
            [key]: value,
            page: 0
          }
      }
    })
  }

  const getListCustomers = filters => {
    getAllCustomers(filters).then(res => {
      setData(res)
      setIsLoading(false)
    })
      .catch(error => {
        if (error?.message?.includes('Network Error')) {
          setTimeout(() => {
            setIsLoading(false)
            setOpenNetworkModal({ type: 'Internet', isOpen: true })
            // setRetryFunction(() => getListCustomers(filters))
          }, 3000);
        }
        else if (error?.response?.status === 403 &&
          error?.response?.data?.error_message?.includes('The Token has expired')) {
          const functionRetry = () => getListCustomers(filters)
          request4RefreshToken(functionRetry)
        }
        else if (error?.response?.status === 403 && error?.response?.data?.error === 'Forbidden') {
          setIsLoading(false)
          history.replace('/')
          // alert('You are not allowed to use this feature. Please login again.')
        }
        else {
          setIsLoading(false)
          console.log('Error at getListCustomers: ', error)
          // history.push({
          //     pathname: '/errors',
          //     state: { error: error.response.status },
          // })
        }
      })
  }

  useEffect(() => {
    setIsLoading(true)
  }, [])

  useEffect(() => {
    if ([filterTypes?.ID, filterTypes?.PHONE, filterTypes?.NAME].includes(changedKey)) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        getListCustomers(filters)
        // getCountPendingRewards(listBookingStatuses.pending)
      }, 350)
    }
    else
      getListCustomers(filters)
  }, [changedKey, filters])


  return {
    filters, updateFilters,
    data,
    isLoading, setIsLoading,
    getListCustomers
  }
}

function CustomerProvider({ children }) {
  const Customer = useCustomerProvider()

  return (
    <CustomerContext.Provider value={Customer}>
      {children}
    </CustomerContext.Provider>
  )
}

export default CustomerProvider