import React from 'react'
import styled from 'styled-components'

const LoadingMask = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const LoadingIcon = styled.i`
    font-size: 2rem;
    color: gray;
`

function Loading() {
  return (
    <LoadingMask>
      <LoadingIcon className="pi pi-spin pi-spinner" />
    </LoadingMask>
  )
}

export default Loading