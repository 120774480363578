import React from 'react'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { useApp } from '../../hooks/AppContext'
import './ModalNetwork.css'

function ModalNetwork() {
  const { openNetworkModal: open, setOpenNetworkModal: setOpen } = useApp()   // retryFunction

  // const handleAccept = () => {
  //   setOpen(false)
  //   // if (retryFunction) retryFunction()
  //   console.log('retryFunction: ', retryFunction);
  // }

  const handleClose = () => {
    setOpen({ ...open, isOpen: false })
  }

  const message = type => type === '500' ? (
    <span>
      The server encountered an internal error or misconfiguration and was unable to complete your request.
      <br/><br/>
      <i className='p-text-secondary'>
        Please contact the developer to inform them of the time this error occurred, and the actions you performed just before this error.
      </i>
    </span>
  ) : (
    <span>
      Please check your network cables, modem and router, then reconnect to Wi-Fi
    </span>
  )

  const footerTemplate = (
    <button className='p-button p-component p-confirm-dialog-reject' onClick={handleClose}>
      <span className='p-button-label p-c'>Close</span>
    </button>
  )

  return (
    <ConfirmDialog visible={open?.isOpen} onHide={handleClose}
      header={open?.type === '500' ? "Internal Server Error" : "No Internet !"}
      message={message(open?.type)}
      icon="pi pi-exclamation-triangle"
      className='network-confirm-dialog'
      footer={footerTemplate}
      // acceptLabel='Retry' accept={handleAccept}
      // rejectLabel='Close' reject={handleClose}
    />
  )
}

export default ModalNetwork