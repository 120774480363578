import React from 'react'
import { Link } from 'react-router-dom'
import { useApp } from '../hooks/AppContext'
import { ModalNetwork } from '.'

function ContentContainer(props) {
    const { pageName, tabName, children } = props
    const { openNetworkModal } = useApp()

    const renderPageName = () => {
      switch (pageName) {
        case 'Check-in / Check-out':
          return (
          <li className="breadcrumb-item active">
            <i className="nav-icon fas fa-user-check" />
            &nbsp;&nbsp;
            {pageName}
          </li>
          )
          
        case 'Appointments':
          return (
          <li className="breadcrumb-item active">
            <i className="nav-icon fa fa-calendar-check" />
            &nbsp;&nbsp;
            {pageName}
          </li>
          )
      
        case 'Rewards':
          return (
          <li className="breadcrumb-item active">
            <i className="nav-icon fas fa-donate" />
            &nbsp;&nbsp;
            {pageName}
          </li>
          )
      
        case 'Booking Time':
          return (
          <li className="breadcrumb-item active">
            <i className="nav-icon fas fa-door-open" />
            &nbsp;&nbsp;
            {pageName}
          </li>
          )
      
        case 'Services':
          return (
          <li className="breadcrumb-item active">
            <i className="nav-icon fas fa-hand-sparkles" />
            &nbsp;&nbsp;
            {pageName}
          </li>
          )
      
        case 'Gallery':
          return (
          <li className="breadcrumb-item active">
            <i className="nav-icon far fa-image" />
            &nbsp;&nbsp;
            {pageName}
          </li>
          )
      
        case "Salon's Profile":
          return (
          <li className="breadcrumb-item active">
            <i className="nav-icon fas fa-address-card" />
            &nbsp;&nbsp;
            {pageName}
          </li>
          )
      
        case "Customer's Messages":
          return (
          <li className="breadcrumb-item active">
            <i className="nav-icon fas fa-envelope" />
            &nbsp;&nbsp;
            {pageName}
          </li>
          )
      
        default:
          return (
          <li className="breadcrumb-item active">
            <i className="nav-icon fas fa-tachometer-alt" />
            &nbsp;&nbsp;
            {pageName}
          </li>
          )
      }
    }

    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        {/* <div className="col-sm-6">
                            <h1>Appointments</h1>
                        </div> */}
                        <div className="col-sm-12">
                            <ol className="breadcrumb float-sm-left">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                {renderPageName()}
                                {tabName && 
                                  <li className="breadcrumb-item active">{tabName}</li>
                                }
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>

            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="card card-default color-palette-box">
                        {/* <div className="card-header">
                            <h3 className="card-title">
                                <i className="fas fa-tag" />
                                {title}
                            </h3>
                        </div> */}
                        <div className="card-body">
                            {children}
                        </div>
                    </div>
                </div>
            </section>

            {/* Modals */}
            {openNetworkModal?.isOpen && <ModalNetwork />}
        </>
    )
}

export default ContentContainer